<template>
    <div ref="container" class="content-container">
        <b-navbar
            variant="faded"
            @click="closeAndUpdate"
        >
            <div
                v-if="!editMode"
                class="header"
            >
                <span class="title">{{ $t('contract_diary.title') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="closeAndUpdate"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer"> {{ $t('common.back') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <transition-group name="fade">
                <div key="search-contract-list">
                    <keep-alive>
                        <contract-diary-search
                            v-if="!editMode"
                            @search="fetchDiaries"
                        />
                    </keep-alive>
                    <div v-if="!editMode" class="col-sm-12">
                        <div
                            class="data-list-item add"
                            @click.stop="addContractDiary"
                        >
                            <div class="item-img-cont add-title">
                                <i class="fa fa-plus"/>
                            </div>
                            <div class="item-text-cont">
                                <span class="item-title-add">{{ $t('common.add_new') }}</span>
                                <font-awesome-icon
                                    icon="file-contract"
                                    class="card-body-icon"
                                />
                            </div>
                        </div>
                    </div>
                    <keep-alive>
                        <contract-diary-list
                            v-if="!editMode"
                            :results="results"
                            @edit="editContractDiary"
                        />
                    </keep-alive>
                </div>
            </transition-group>
            <transition name="fade">
                <div v-if="editMode" class="pl-3 pr-3">
                    <contract-diary-editor
                        v-model="contractDiary"
                        :report-list-view="false"
                        :show-ely-fields="isElyContract"
                        @close="showList"
                        @closeAndUpdate="closeAndUpdate"
                    />
                </div>
            </transition>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import {restApi} from "@/components/mixins/RestApiMixin";
import {workManagementHelper} from '@/components/mixins/WorkManagementMixin'
import ContractDiaryList from "@/components/contractreport/ContractDiaryList.vue";
import ContractDiaryEditor from "@/components/contractreport/ContractDiaryEditor.vue";
import ContractDiarySearch from "@/components/contractreport/ContractDiarySearch";
import {EventBus} from "@/event-bus";

export default {
    name: "contract-diaries",
    components: {ContractDiaryList, ContractDiarySearch, ContractDiaryEditor},
    mixins: [timeUtils, restApi, workManagementHelper],
    data: function () {
        return {
            editMode: false,
            contractDiary: null,
            lastSearchedParams: null,
            results: [],
            isElyContract: false,
            loading: false
        }
    },
    methods: {
        fetchDiaries: function (params) {
            this.results = []
            if(!params || !params.contract) {
                EventBus.$emit('show-alert', this.$t('contract_diary.invalid_contract'))
                return undefined
            }
            this.isElyContract = params.contract.ely_contract
            let mappedParams = {
                contract: params.contract ? params.contract.id : null,
                from: params.startDate ? this.getDateIsoString(new Date(params.startDate)) : null,
                to: params.endDate ? this.getDateIsoString(new Date(params.endDate)) : null,
            }
            this.lastSearchedParams = params
            this.loading = true
            this.restFetchParams(this.contractDiaryUrl, mappedParams, this.handleResponse)

        },
        handleResponse: function (response) {
            if (response && response.data) {
                this.results = response.data
            }
            this.loading = false
        },
        editContractDiary(diary) {
            this.contractDiary = this.jsonToItem(diary);
            this.editMode = true
        },
        addContractDiary() {
            this.contractDiary = this.newContractDiary()
            this.editMode = true
        },
        showList() {
            this.contractDiary = null
            this.editMode = false
        },
        closeAndUpdate: function () {
            this.contractDiary = null
            if(this.lastSearchedParams && this.lastSearchedParams.contract) {
                this.fetchDiaries(this.lastSearchedParams)
            }
            this.showList()
        },
    }
}
</script>
