<template>
    <div
        id="editor"
        class="col-sm-12 nopads"
    >
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-data">
                <h4 class="form-header">{{ $t('contracts.basic_info')}}</h4>

                <div
                    v-if="isAdmin"
                    class="nopads"
                >
                  <span class="span-title">
                    {{ $t('users.company') }}
                  </span>
                    <b-form-select
                        v-if="vehicleUpdate.id < 1"
                        id="company"
                        v-model="company"
                        size="sm"
                        class="mb-3"
                        :disabled="!companies || companies.length < 1"
                    >
                        <template slot="first">
                            <option
                                :value="null"
                                disabled
                            >
                                {{ $t('users.select_company') }}
                            </option>
                        </template>
                        <option
                            v-for="c in companies"
                            :key="c.id"
                            :value="c.id"
                        >
                            {{ c.name }}
                        </option>
                    </b-form-select>
                    <span
                        v-else
                    >
            {{ vehicleUpdate.company.name }}
          </span>
                </div>
                <b-row class="nopads">
                    <div class="col-md-6 nopads p-1">
                        <span class="span-title">
                          {{ $t('vehicle_editor.registration_number') }}
                        </span>
                        <b-form-input
                            id="license_plate"
                            v-model="vehicleUpdate.license_plate"
                            type="text"
                            size="sm"
                        />
                    </div>
                    <div class="col-md-6 nopads p-1">
                        <span class="span-title">
                          {{ $t('vehicle_editor.type') }}
                        </span>
                        <b-form-select
                            id="vehicle_type"
                            v-model="vehicleUpdate.vehicle_type"
                            :options="vehicle_types"
                            size="sm"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-md-6 nopads p-1">
                        <span class="span-title">
                            {{ $t('vehicle_editor.make') }}
                        </span>
                        <b-form-input
                            id="make"
                            v-model="vehicleUpdate.make"
                            type="text"
                            size="sm"
                        />
                    </div>
                    <div class="col-md-6 nopads p-1">
                        <span class="span-title">
                          {{ $t('vehicle_editor.model') }}
                        </span>
                        <b-form-input
                            id="vehicle_model"
                            v-model="vehicleUpdate.vehicle_model"
                            type="text"
                            size="sm"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-md-6 nopads p-1">
                        <span class="span-title">
                          {{ $t('vehicle_editor.in_use') }}
                        </span>
                        <b-form-checkbox
                            id="in_use"
                            v-model="vehicleUpdate.in_use"
                            size="sm"
                        />
                    </div>
                </b-row>

                <hr/>
                <h4 class="form-header">{{ $t('vehicle_editor.work_accessory_info')}}</h4>
                <b-row class="nopads">
                    <div class="col-md-6 nopads p-1">
                        <span class="span-title">
                          {{ $t('vehicle_editor.plow_width') }}
                        </span>
                        <b-form-input
                            id="plow_width"
                            v-model="vehicleUpdate.plow_width"
                            min="0"
                            max="20"
                            type="number"
                            size="sm"
                        />
                    </div>
                    <div class="col-md-6 nopads p-1">
                        <span class="span-title">
                          {{ $t('vehicle_editor.spread_width') }}
                        </span>
                        <b-form-input
                            id="spread_width"
                            v-model="vehicleUpdate.spread_width"
                            min="0"
                            max="20"
                            type="number"
                            size="sm"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-md-6 nopads p-1">
                        <span class="span-title">
                          {{ $t('vehicle_editor.spreader_id') }}
                        </span>
                        <b-form-input
                            id="spreader_id"
                            v-model="vehicleUpdate.spreader_id"
                            type="text"
                            size="sm"
                        />
                    </div>
                    <div class="col-md-6 nopads p-1">
                        <span class="span-title">
                          {{ $t('vehicle_editor.spreader_manufacturer') }}
                        </span>
                        <b-form-select
                            id="fuel_type"
                            v-model="vehicleUpdate.spreader_manufacturer"
                            :options="spreader_manufacturers"
                            size="sm"
                        />

                    </div>
                </b-row>
                <hr/>
                <h4 class="form-header">{{ $t('vehicle_editor.additional_info')}}</h4>
                <b-row class="nopads">
                    <div class="col-md-6 nopads p-1">
                        <span class="span-title">
                          {{ $t('vehicle_editor.co2_emissions') }}
                        </span>
                        <b-form-input
                            id="co2_emission"
                            v-model="vehicleUpdate.co2_emission"
                            min="0"
                            type="number"
                            size="sm"
                        />
                    </div>
                    <div class="col-md-6 nopads p-1">
                        <span class="span-title">
                              {{ $t('vehicle_editor.vin') }}
                        </span>
                        <b-form-input
                            id="vin"
                            v-model="vehicleUpdate.vin"
                            type="text"
                            size="sm"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-md-6 nopads p-1">
                        <span class="span-title">
                            {{ $t('vehicle_editor.fuel_type') }}
                        </span>
                        <b-form-select
                            id="fuel_type"
                            v-model="vehicleUpdate.fuel_type"
                            :options="fuel_types"
                            size="sm"
                        />
                    </div>
                    <div class="col-md-6 nopads p-1">
                        <span class="span-title">
                            {{ $t('vehicle_editor.fuel_consumption') }}
                        </span>
                        <b-form-input
                            id="fuel_consumption"
                            v-model="vehicleUpdate.fuel_consumption"
                            min="0"
                            type="number"
                            size="sm"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-md-6 nopads p-1">
                        <span class="span-title">
                          {{ $t('vehicle_editor.engine_capacity') }}
                        </span>
                        <b-form-input
                            id="displacement"
                            v-model="vehicleUpdate.displacement"
                            :min="0"
                            type="number"
                            @change="vehicleUpdate.displacement = vehicleUpdate.displacement >= 0 ? vehicleUpdate.displacement : null"
                            size="sm"
                        />
                    </div>
                    <div class="col-md-6 nopads p-1">
                        <span class="span-title">
                          {{ $t('vehicle_editor.weight') }}
                        </span>
                        <b-form-input
                            id="weight"
                            v-model="vehicleUpdate.weight"
                            type="number"
                            :min="0"
                            @change="vehicleUpdate.weight = vehicleUpdate.weight >= 0 ? vehicleUpdate.weight : null"
                            size="sm"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-md-6 nopads p-1">
                        <span class="span-title">
                          {{ $t('vehicle_editor.last_inspection') }}
                        </span>
                        <b-form-input
                            id="last_inspection"
                            type="date"
                            :value="timestampToDateInput(vehicleUpdate.last_inspection)"
                            size="sm"
                            @change="updateLastInspection"
                        />
                    </div>
                    <div class="col-md-6 nopads p-1">
                        <span class="span-title">
                          {{ $t('vehicle_editor.next_inspection') }}
                        </span>
                        <b-form-input
                            id="next_inspection"
                            type="date"
                            :value="timestampToDateInput(vehicleUpdate.next_inspection)"
                            size="sm"
                            @change="updateNextInspection"
                        />
                    </div>
                </b-row>

                <b-row class="nopads">
                    <span class="span-title pl-1">{{ $t('vehicle_editor.add_photo') }}</span>
                    <div
                        v-for="(path, counter) in photos"
                        :key="counter"
                        class="col-sm-12 nopads"
                    >
                        <b-form-group
                            class="title"
                            label-for="counter"
                            v-if="counter === 0 || isAttachmentSet(photos, counter-1)"
                        >
                            <b-form-file
                                v-model="photos[counter]"
                                size="sm"
                                accept="image/jpeg, image/jpg, image/png"
                                :placeholder="$t('common.photo_placeholder')"
                            />
                        </b-form-group>
                    </div>
                </b-row>
                <div class="col-sm-12 button-container">
                    <b-button
                        v-if="vehicle.id < 1"
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">
                          {{ $t('common.cancel') }}
                        </span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="submitVehicle"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">
              {{ $t('common.save') }}
            </span>
                    </b-button>
                </div>
            </div>


        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import {vehicleFuel, vehicleHelper} from '../mixins/VehicleMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {mapHelper} from "@/components/mixins/MapMixin";
import {attachmentHelper} from "@/components/mixins/AttachmentMixin";

export default {
    name: 'VehicleEditor',
    mixins: [vehicleHelper, restApi, timeUtils, mapHelper, attachmentHelper],
    props: {
        vehicle: {
            type: Object,
            default: null
        },
        closeWhenUpdate: {
            type: Boolean,
            default: false
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            company: null,
            vehicleLocation: null,
            lastLocationTime: '',
            companies: [],
            photos: new Array(5),
            vehicle_types: [
                {value: 1, text: this.$t('vehicle_list.vehicle_type.passenger_or_van')},
                {value: 2, text: this.$t('vehicle_list.vehicle_type.truck')},
                {value: 3, text: this.$t('vehicle_list.vehicle_type.trailer')},
                {value: 4, text: this.$t('vehicle_list.vehicle_type.trailer1')},
                {value: 5, text: this.$t('vehicle_list.vehicle_type.loader')},
                {value: 6, text: this.$t('vehicle_list.vehicle_type.tractor')},
                {value: 11, text: this.$t('vehicle_list.vehicle_type.snowplow')},
                {value: 7, text: this.$t('vehicle_list.vehicle_type.spreader')},
                {value: 8, text: this.$t('vehicle_list.vehicle_type.harvester')},
                {value: 9, text: this.$t('vehicle_list.vehicle_type.forwarder')},
                {value: 12, text: this.$t('vehicle_list.vehicle_type.helicopter')},
                {value: 13, text: this.$t('vehicle_list.vehicle_type.paver')},
                {value: 14, text: this.$t('vehicle_list.vehicle_type.road_fence')},
                {value: 15, text: this.$t('vehicle_list.vehicle_type.shock_absorber')},
                {value: 16, text: this.$t('vehicle_list.vehicle_type.digger')},
                {value: 10, text: this.$t('vehicle_list.vehicle_type.other')}
            ],
            fuel_types: [
                {value: vehicleFuel.UNKNOWN, text: this.getFuelTypeName(vehicleFuel.UNKNOWN)},
                {value: vehicleFuel.FUEL_98E, text: this.getFuelTypeName(vehicleFuel.FUEL_98E)},
                {value: vehicleFuel.FUEL_95E10, text: this.getFuelTypeName(vehicleFuel.FUEL_95E10)},
                {value: vehicleFuel.DIESEL, text: this.getFuelTypeName(vehicleFuel.DIESEL)},
                {value: vehicleFuel.GAS_CNG, text: this.getFuelTypeName(vehicleFuel.GAS_CBG)},
                {value: vehicleFuel.GAS_CBG, text: this.getFuelTypeName(vehicleFuel.GAS_CNG)},
                {value: vehicleFuel.GAS_LNG, text: this.getFuelTypeName(vehicleFuel.GAS_LNG)},
                {value: vehicleFuel.HYBRID, text: this.getFuelTypeName(vehicleFuel.HYBRID)},
                {value: vehicleFuel.ELECTRIC, text: this.getFuelTypeName(vehicleFuel.ELECTRIC)},
                {value: vehicleFuel.OTHER, text: this.getFuelTypeName(vehicleFuel.OTHER)}
            ],
            spreader_manufacturers: [
                {value: null, text: this.$t('common.unknown')},
                {value: 1, text: 'Arctic Machine'},
                {value: 2, text: 'Rasko'},
                {value: 3, text: 'Fleetlogis'},
            ],
            vehicleUpdate: this.jsonToVehicle(this.vehicle)
        }
    },
    mounted: function () {
        if (this.isAdmin) {
            this.fetchCompanies()
            if (this.vehicleUpdate.id > 0) {
                this.company = this.vehicleUpdate.company.id
            }
        }
        this.vehicle_types.sort((a, b) => {
            if (a.value === 10) {
                return 1
            }
            return a.text.localeCompare(b.text, this.$i18n.locale)
        })
    },
    methods: {
        fetchCompanies: function () {
            if (this.isAdmin) {
                this.loading = true
                this.restFetch(this.companyUrl, this.handleCompanies)
            }
        },

        handleCompanies: function (response) {
            this.companies = response.data
            this.loading = false
        },

        updateLastInspection: function (event) {
            if (event) {
                this.vehicleUpdate.last_inspection = new Date(event)
            } else {
                this.vehicleUpdate.last_inspection = undefined
            }
        },
        updateNextInspection: function (event) {
            if (event) {
                this.vehicleUpdate.next_inspection = new Date(event)
            } else {
                this.vehicleUpdate.next_inspection = undefined
            }
        },
        setDefaultValues: function (vehicle) {
            if (this.isAdmin) {
                if (!this.company) {
                    EventBus.$emit('show-alert', this.$t('vehicle_editor.missing_company'))
                    return undefined
                }
                vehicle.company = {id: this.company}
            }
            if (!vehicle.license_plate) {
                EventBus.$emit('show-alert', this.$t('vehicle_editor.missing_license_plate'))
                return undefined
            }
            if (!vehicle.vehicle_type) {
                EventBus.$emit('show-alert', this.$t('vehicle_editor.missing_type'))
                return undefined
            }
            if (!vehicle.displacement) {
                vehicle.displacement = 0
            }
            if (vehicle.last_inspection && (vehicle.last_inspection instanceof Date)) {
                vehicle.last_inspection = vehicle.last_inspection.toISOString()
            }
            if (vehicle.next_inspection && (vehicle.next_inspection instanceof Date)) {
                vehicle.next_inspection = vehicle.next_inspection.toISOString()
            }
            if (!vehicle.plow_width) {
                vehicle.plow_width = 0
            } else {
                vehicle.plow_width = parseFloat(vehicle.plow_width)
            }
            if (!vehicle.spread_width) {
                vehicle.spread_width = 0
            } else {
                vehicle.spread_width = parseFloat(vehicle.spread_width)
            }

            return vehicle
        },

        submitVehicle: function () {
            var jsonVehicle = this.setDefaultValues(this.vehicleUpdate)
            if (jsonVehicle) {
                this.loading = true
                if (this.vehicle.id < 1) {
                    this.restAdd(this.vehicleUrl, jsonVehicle, this.success, this.fail)
                } else {
                    this.restUpdate(this.vehicleUrl, jsonVehicle, this.success, this.fail)
                }
            }
        },

        success: function (response) {
            let photos = this.getValidAttachments(this.photos)
            if (photos.length > 0) {
                this.uploadPhoto(photos, response.id)
            } else {
                this.uploadCompleted()
            }
        },

        uploadCompleted: function () {
            if (this.vehicle.id < 1 || this.closeWhenUpdate) {
                this.$emit('added')
            }
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_add_success'), 'success')
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },

        uploadPhoto: function (photos, id) {
            if (photos.length > 0) {
                this.loading = true
                let formData = new FormData()
                photos.forEach(photoFile => {
                    if (photoFile !== undefined && photoFile !== null) {
                        formData.append('photos[]', photoFile)
                    }
                })
                let url = this.vehicleUrl + "/" + id + "/photo"
                this.restPostWithFile(url, {}, formData, this.photoSuccess, this.fail)
            }
        },

        photoSuccess: function () {
            this.loading = false
            if (this.vehicle.id < 1 || this.closeWhenUpdate) {
                this.$emit('added')
            }
            EventBus.$emit('show-alert', this.$t('common.alert_add_success'), 'success')
        }
    }
}
</script>
