<template>
    <div>
        <div v-if="showCarSimulating" class="col-sm-6 trip-simulation-container pl-1 pb-1 pr-1" style="float: right">
            <div>
                <b-row>
                    <div class="col-4">
                        <div class="col-12 speed-value-container text-right">
                            {{ trip && trip.speed && trip.speed[currentPoint] !== null ? trip.speed[currentPoint] : '-' }}
                        </div>
                    </div>
                    <div class="col-3 speed-container text-left"> km/h </div>
                    <div class="col-3 text-center">
                        <div class="player-control-container">
                            <font-awesome-icon
                                v-if="carPositionSimulating"
                                icon="pause"
                                class="player-contols"
                                v-on:click.stop="pauseCarPosition"
                            />
                            <font-awesome-icon
                                v-else
                                icon="play"
                                class="player-contols"
                                style="margin-left: .55em"
                                v-on:click.stop="continueCarPosition"
                            />
                        </div>
                    </div>
                   <div class="col-2 text-center">
                        <b-row class="p-1 speed-button" v-on:click.stop="increaseSpeed"> + </b-row>
                        <b-row class="p-1 speed-button" v-on:click.stop="decreaseSpeed"> - </b-row>
                    </div>
                </b-row>
                <div class="col-12 player-sub-value text-center">
                    {{ trip && trip.timestamps && trip.timestamps[currentPoint] ? toLocalTime(trip.timestamps[currentPoint])  : '-' }}
                </div>
                <div class="slider-container nopads p-1 pl-2 pr-2">
                    <vue-slider
                        v-model="currentPoint"
                        :min="0"
                        :tooltip="'none'"
                        :max="maxValue"
                        @change="changeCarPosition"
                        :dot-style="dotStyle"
                        :process-style="processStyle"/>
                </div>
                <b-row>
                    <div class="col-6 player-subtitle">Matka - km</div>
                    <div class="col-6 player-subtitle">Aika</div>
                </b-row>
                <b-row>
                    <div class="col-3 player-sub-value text-right">{{ Math.min(trip.length, distanceProgress / 1000).toFixed(2) }}</div>
                    <div class="col-3 player-sub-value text-left">/ {{ trip.length.toFixed(2) }}</div>
                    <div class="col-3 player-sub-value text-right">{{ getWorkTime(Math.round(timeProgress)) }}</div>
                    <div class="col-3 player-sub-value text-left">/ {{ getWorkTime(Math.round(tripTime)) }} </div>
                </b-row>
            </div>
        </div>

        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {logModes, vehicleHelper} from '../mixins/VehicleMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {mapHelper} from '../mixins/MapMixin'
import {measureDistanceMixin} from "@/components/mixins/MeasureDistanceMixin";

export default {
    name: 'TripMap',
    mixins: [timeUtils, vehicleHelper, mapHelper, measureDistanceMixin],
    props: {
        tripResults: {
            type: Array,
            default: function () {
                return []
            }
        },
        tripItem: {
            type: Object,
            default: null
        },
        map: {
            type: Object,
            default: null
        },
        areaGroup: {
            type: Object,
            default: null
        },
        customArea: {
            type: Array,
            default: null
        },
        showTripInfo: {
            type: Boolean,
            default: true
        },
        focus: {
            type: Boolean,
            default: true
        },
        lineOpacity: {
            type: Number,
            default: 1
        },
        lineWidth: {
            type: Number,
            default: 0
        },
        staticLineColor: {
            type: String,
            default: null
        },
        pointHover: {
            type: Boolean,
            default: false
        },
    },

    data: function () {
        return {
            loading: false,
            showDetails: false,
            trips: [],
            trip: null,
            intervalNum: null,
            currentPoint: 0,
            markerStore: null,
            carMarkerId: 88888,
            simulationSpeed: 50,
            tripMapColor: null,
            visibleGroup: null,
            showCarSimulating: false,
            carPositionSimulating: false,
            distanceProgress: 0,
            timeProgress: 0,
            tripTime: 0,
            dotStyle: {
                backgroundColor: '#BEFF41', // Custom color for the thumb
                borderColor: '#90b677'
            },
            processStyle: {
                height: '.25em',
                backgroundColor: '#BEFF41' // Custom color for the filled track
            },
            fields: [
                {
                    driver: {
                        label: this.$t('trip_list.driver'),
                        sortable: false
                    }
                },
                {
                    vehicle: {
                        label: this.$t('trip_list.vehicle'),
                        sortable: false
                    }
                },
                {
                    mode: {
                        label: this.$t('trip_list.mode'),
                        sortable: false
                    }
                },
                {
                    customer: {
                        label: this.$t('contracts.customer'),
                        sortable: true
                    }
                },
                {
                    contract: {
                        label: this.$t('orders.contract'),
                        sortable: true
                    }
                },
                {
                    order: {
                        label: this.$t('vehicle_position.order'),
                        sortable: true
                    }
                },
                {
                    startTime: {
                        label: this.$t('trip_list.start_time'),
                        sortable: false
                    }
                },
                {
                    startRoad: {
                        label: this.$t('trip_list.start_loc'),
                        sortable: false
                    }
                },
                {
                    endTime: {
                        label: this.$t('trip_list.end_time'),
                        sortable: false
                    }
                },
                {
                    endRoad: {
                        label: this.$t('trip_list.end_loc'),
                        sortable: false
                    }
                },
                {
                    length: {
                        label: this.$t('trip_list.trip_length'),
                        sortable: false
                    }
                }
            ]
        }
    },

    computed: {
        maxValue() {
            return this.trip.timestamps.length > 0 ? this.trip.timestamps.length - 1 : 0;
        }
    },

    watch: {
        tripResults: function () {
            this.initView()
        },
        tripItem: function () {
            this.resetMap()
            this.initView()
        },
        map: function () {
            if (this.map) {
                this.resetMap()
                this.initView()
            }
        }
    },

    mounted: function () {
        this.initView()
    },
    methods: {

        initView: function () {
            this.markerStore = this.map.getMarkerStore()
            if (this.trips || this.trip) {
                this.hideTrips()
            }
            this.trips = this.tripResults
            this.trip = this.tripItem
            if (this.trip && this.trip.geometry) {
                this.drawTrip(this.trip, this.showTripInfo)
            } else if (this.trips && this.trips.length > 0) {
                this.drawTrips(this.focus)
            }
            this.showAreas()
        },

        decreaseSpeed() {
            if (this.simulationSpeed < 300) {
                this.stopUpdateCarPosition()
                this.simulationSpeed += 10;
                this.continueCarPosition()
            }
        },

        increaseSpeed() {
            if (this.simulationSpeed > 10) {
                this.stopUpdateCarPosition()
                this.simulationSpeed -= 10;
                this.continueCarPosition()
            }
        },

        initMarkerStore: function () {
            this.markerStore = this.map.getMapMarkerStore()
        },

        showMovingCar: function () {
            if(!this.carPositionSimulating) {
                this.map.removePolyline(this.trip.id, this.TRACE)
                this.map.drawSimulatedRoute(this.trip.id, this.trip.geometry)
                this.currentPoint = 0
                this.distanceProgress = 0
                this.timeProgress = 0
                let timeA = new Date(this.trip.timestamps[0])
                let timeB = new Date(this.trip.timestamps[this.trip.timestamps.length-1])
                this.tripTime = (timeB.getTime() - timeA.getTime()) / (1000 * 60)
                this.showCarSimulating = true
                this.carPositionSimulating = true
                this.$emit('carPositionSimulating', this.carPositionSimulating)
                let repetitionInterval = 50
                this.intervalNum = setInterval(() => this.updateCarPosition(), repetitionInterval)
            }
        },

        stopUpdateCarPosition: function () {
            this.carPositionSimulating = false
            this.currentPoint--
            clearInterval(this.intervalNum)
        },

        hideSimulatedCar: function () {
            if(this.carMarkerId){
                this.map.removeMapMarker(this.carMarkerId, this.VEHICLE)
            }
        },

        hideSimulatedTracks: function () {
            if(this.trip){
                this.map.removePolyline(this.trip.id, this.TRACE)
                this.map.hideSimulatedRoute(this.trip.id)
            }
        },

        resetMap: function () {
            this.showCarSimulating = false
            this.stopUpdateCarPosition()
            this.hideSimulatedCar()
            this.hideSimulatedTracks()
            this.hideAreas()
            this.hideTrips()
        },

        updateCarPosition: function () {
            this.currentPoint++
            if (this.trip && this.currentPoint < this.trip.geometry.length) {
                this.$emit('setCurrentPoint', this.currentPoint)
                this.map.showMapMarker(this.carMarkerId, this.VEHICLE,
                    this.trip.geometry[this.currentPoint].y,
                    this.trip.geometry[this.currentPoint].x,
                    this.markerStore.getActiveCarIcon())
                // Update distance and update time
                this.distanceProgress += this.getPointDistance(this.trip.geometry[this.currentPoint-1], this.trip.geometry[this.currentPoint])
                let timeA = new Date(this.trip.timestamps[this.currentPoint-1])
                let timeB = new Date(this.trip.timestamps[this.currentPoint])
                this.timeProgress += (timeB.getTime() - timeA.getTime()) / (1000 * 60)
                // Update car on the map
                this.map.drawRouteProgressPolyLine(this.currentPoint, this.trip.id, false, this.tripMapColor)
            } else {
                this.stopUpdateCarPosition()
            }
        },

        changeCarPosition: function () {
            if (this.trip && this.currentPoint < this.trip.geometry.length && this.currentPoint >= 0) {
                // Emit the current point for any external listeners
                this.$emit('setCurrentPoint', this.currentPoint);
                // Update the car marker position on the map
                this.map.showMapMarker(
                    this.carMarkerId,
                    this.VEHICLE,
                    this.trip.geometry[this.currentPoint].y,
                    this.trip.geometry[this.currentPoint].x,
                    this.markerStore.getActiveCarIcon()
                );

                this.distanceProgress = 0;
                for (let i = 1; i <= this.currentPoint; i++) {
                    this.distanceProgress += this.getPointDistance(this.trip.geometry[i - 1], this.trip.geometry[i]);
                }

                this.timeProgress = 0;
                for (let i = 1; i <= this.currentPoint; i++) {
                    let timeA = new Date(this.trip.timestamps[i - 1]);
                    let timeB = new Date(this.trip.timestamps[i]);
                    this.timeProgress += (timeB.getTime() - timeA.getTime()) / (1000 * 60); // Convert to minutes
                }

                // Update the route progress on the map
                this.map.drawRouteProgressPolyLine(this.currentPoint, this.trip.id, false, this.tripMapColor);
            }
        },

        pauseCarPosition: function () {
            this.carPositionSimulating = false
            this.$emit('carPositionSimulating', this.carPositionSimulating)
            clearInterval(this.intervalNum)
        },

        continueCarPosition() {
            if (this.currentPoint === this.trip.geometry.length-1) {
                this.showMovingCar()
            } else {
                this.carPositionSimulating = true
                this.$emit('carPositionSimulating', this.carPositionSimulating)
                this.intervalNum = setInterval(() => this.updateCarPosition(), this.simulationSpeed)
            }
        },

        drawTrips: function (focus = true) {
            this.trips.forEach(function (trip) {
                this.drawTrip(trip, false)
            }, this)
            // Focus map to first trip's starting point
            if (this.trips && this.trips.length > 0 && this.trips[0].geometry && focus) {
                this.map.zoomToPosition(this.trips[0].geometry[0]['y'], this.trips[0].geometry[0]['x'])
            }
        },

        drawTrip: function (trip, showInfo) {
            if (trip.geometry) {
                let color = ''
                let dash = false
                const arrow = false
                let width = this.lineWidth > 0 ? this.lineWidth : 3
                switch (trip.mode) {
                    case logModes.WORK:
                        width = 5
                        if (trip.color) {
                            color = this.staticLineColor ? this.staticLineColor : trip.color
                        } else {
                            color = this.staticLineColor ? this.staticLineColor : '#00FF21'
                        }
                        break
                    case logModes.PROGRAM:
                        color = '#2121FF'
                        break
                    case logModes.RESETTLE:
                        color = this.staticLineColor ? this.staticLineColor : '#89898a'
                        dash = true
                        break
                }
                this.tripMapColor = color
                this.map.drawPolyLine(trip.id, this.TRACE, trip.geometry, color, dash, arrow, width, this.lineOpacity, false, false, null, this.pointHover)
                if (this.trip) {
                    this.$nextTick(() => {
                        this.map.zoomToPosition(this.trip.geometry[0]['y'], this.trip.geometry[0]['x'])
                    })
                }
            }
            if (showInfo) {
                this.showInfo(trip)
            }
        },

        hideTrips: function () {
            if (this.trips) {
                this.trips.forEach(function (trip) {
                    this.map.removePolyline(trip.id, this.TRACE)
                }, this)
                this.trips = []
            }
            if (this.trip) {
                this.map.removePolyline(this.trip.id, this.TRACE)
                this.trip = null
            }
            this.map.hideMapInfo()
            this.showDetails = false
        },

        showInfo: function (trip) {
            this.map.showMapInfo(this.fields, [{
                driver: trip.driver,
                vehicle: trip.vehicle,
                mode: this.getTripModeString(trip.mode),
                customer: trip.customer ? trip.customer : '-',
                contract: trip.contract ? trip.contract : '-',
                order: trip.order ? trip.order : '-',
                startTime: this.getDateString(trip.start_time),
                startRoad: trip.start_road,
                endTime: this.getDateString(trip.end_time),
                endRoad: trip.end_road,
                length: parseFloat(trip.length.toFixed(1)).toLocaleString()
            }], true)
            if (trip.geometry) {
                this.map.zoomToPosition(trip.geometry[0]['y'], trip.geometry[0]['x'])
            }
        },

        onMapInfoClosed: function () {
            this.showDetails = false
        },

        onPolylineTap: function (data) {
            if (data.type && data.type === this.TRACE) {
                if (this.showDetails) {
                    this.map.hideMapInfo()
                    this.showDetails = false
                } else {
                    // Show info for the corresponding item
                    if (this.trips && this.trips.length > 0) {
                        var trip = this.trips.find(trip => trip.id === data.id)
                        if (trip) {
                            this.showInfo(trip)
                            this.showDetails = true
                        }
                    } else if (this.trip && this.trip.id === data.id) {
                        this.showInfo(this.trip)
                        this.showDetails = true
                    }
                }
            }
        },

        isCarPositionSimulating() {
            return this.carPositionSimulating
        },

        showAreas: function () {

            this.hideAreas()
            if (this.areaGroup) {
                this.areaGroup.areas.forEach(area =>  {
                    if (area.color) {
                        this.map.drawPolygon(area.id, area.geometry.rings, this.AREA,  area.color, area.color, 0.4)
                    } else {
                        this.map.drawPolygon(area.id, area.geometry.rings)
                    }
                })
                this.visibleGroup = this.areaGroup
            }

            if (this.customArea) {
                this.map.drawPolygon("customArea", this.customArea)
            }
        },

        hideAreas: function () {
            if (this.visibleGroup) {
                this.visibleGroup.areas.forEach(area => this.map.removePolygon(area.name))
                this.visibleGroup = null
            }
            this.map.removePolygon("customArea")
        }

    }
}
</script>
<style scoped>
    .speed-button {
        font-weight: 600;
        text-align: center;
        margin: .1em;
        height: 1.1em;
        cursor: pointer;
        padding: 0;
        font-size: 2em;
        color: #90b677;
    }

    .speed-button:hover {
        font-weight: 800;
        color: #00FF21;
    }

    .speed-button:focus, .speed-button::selection {
        background-color: transparent;
    }

    .trip-simulation-container {
        color: #FFFFFF;
        background-color: rgba(43, 43, 43, 0.8);
        border: 1px solid #404041;
        min-width: 30vw;
        z-index: 2;
        position: relative;
        width: fit-content;
        border-radius: .5em;
        margin: .5em;
    }

    .speed-container {
        margin-top: .6em;
        text-align: center;
        font-size: 2em;
        font-weight: bold;
        text-shadow: 3px 3px #000000;
        color: #FFFFFF;
        padding: .25em;
    }

    .speed-value-container {
        text-align: center;
        font-size: 3em;
        font-weight: bold;
        text-shadow: 3px 4px #000000;
        color: #FFFFFF;
        padding: .1em;
        padding-top: .2em;
    }

    .player-control-container {
        margin: auto;
        margin-top: .5em;
        color: #FFFFFF;
        font-size: 2em;
        height: 2em;
        width: 2em;
        text-align: center;
        border-radius: 1em;
        border: solid 2px #FFFFFF;
    }

    .player-contols {
        margin: .45em;
        cursor: pointer;
    }

    .player-subtitle {
        color: #FFFFFF;
        text-transform: uppercase;
        text-align: center;
        text-shadow: 3px 3px #000000;
        padding: 0;
        padding-left: .2em;
        padding-right: .2em;
    }

    .player-sub-value {
        color: #FFFFFF;
        font-size: 1.4em;
        text-shadow: 3px 3px #000000;
        padding: 0;
        padding-left: .2em;
        padding-right: .2em;
    }


</style>
