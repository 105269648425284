<template xmlns:v-resize="http://www.w3.org/1999/xhtml">
    <div
        id="mapview"
        ref="mapView"
        v-resize:debounce="resizeMap"
        class="content-container"
        @contextmenu="openMenu"
    >
        <div
            id="map-canvas"
            ref="map-canvas"
            class="map-style"
            @touchstart="touchmove"
            @mousemove="mousemove"
        />
        <transition name="fade">
            <div
                v-if="showInfo"
                class="map-info"
                :class="{top : infoOnTop}"
            >
                <div v-if="!infoOnTop" class="map-info-gradient"/>
                <map-info-table
                    class="map-info-content"
                    :fields="infoFields"
                    :data="infoContent"
                    :onTop="infoOnTop"
                    @click="hideMapInfoCallback"
                />
                <div v-if="infoOnTop" class="map-info-gradient-reverse"/>
            </div>
        </transition>
        <b-button-group vertical
                        v-if="contextMenuVisible"
                        id="right-click-menu"
                        :style="{top: topMenu, left: leftMenu}"
                        tabindex="-1"
                        @blur="hideContextMenu">
            <b-button
                size="sm"
                class="popup-menu-item"
                @click.stop="copyCoordinates">{{ $t('map.copy_coordinates') }}
            </b-button>
            <div
                v-for="(item, index) in menuItems.filter(x => x != null)"
                :key="index"
                class="nopads">
                <b-dropdown
                    v-if="item.subItems"
                    id="index"
                    size="sm"
                    no-caret
                    dropright
                    class="popup-menu-item"
                    :text="item.text"
                >
                    <template #button-content>
                        <div class="popup-menu-item">{{ item.text }}</div>
                    </template>
                    <b-dropdown-item
                        v-for="subItem in item.subItems"
                        :key="subItem.text"
                        size="sm"
                        variant="dark"
                        class="popup-sub-menu-item nopads"
                        :class="{'text-disabled' : subItem.disabled}"
                        @click.stop="menuItemClicked(subItem)">
                        <span class="nopads" :class="{'text-disabled' : subItem.disabled}">
                              {{ subItem.text }}
                        </span>
                    </b-dropdown-item>
                </b-dropdown>
                <b-button
                    v-else
                    size="sm"
                    class="popup-menu-item"
                    @click.stop="menuItemClicked(item)">
                    {{ item.text }}
                </b-button>
            </div>
        </b-button-group>
        <div class="leaflet-bottom leaflet-right" style="margin-right: 1em; margin-bottom: 8.5em">
            <div
                @click="watchUserPosition"
                class="H_ctl H_btn"
                :class="{'bg-primary': watchUserPositionId && !isLoadingPosition}"
                style="bottom: 40px; font-size: 10px"
            >
                <svg class="H_icon" style="width: 2em; height: 2em;">
                    <font-awesome-icon icon="crosshairs" :class="{pulse: isLoadingPosition}" />
                </svg>
            </div>
            <div
                v-if="watchUserPositionId && !isLoadingPosition"
                @click="clearWatchUserPosition"
                class="H_ctl H_btn"
                style="bottom: 40px; font-size: 10px"
            >
                <svg class="H_icon" style="width: 2em; height: 2em;">
                    <font-awesome-icon icon="times"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
/* global H */
import resize from 'vue-resize-directive'
import MapInfoTable from './MapInfoTable'
import MapMarkers from './MapMarkerStore'
import Vue from 'vue'
import {getDistance} from 'geolib'
import {mapHelper, userTracking} from '../mixins/MapMixin'
import {restApi} from "../mixins/RestApiMixin"

export default {
    name: 'HereMap',
    components: {MapInfoTable},
    directives: {
        resize
    },
    mixins: [mapHelper, restApi, userTracking],
    props: {
        menuItems: {
            type: Array,
            default: () => []
        },
        center: {
            type: Object,
            default: null
        },
        findUser: {
            type: Boolean,
            default: function () {
                return true
            }
        },
        isEditing: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        menuEnabled: {
            type: Boolean,
            default: function () {
                return true
            }
        }
    },
    data: function () {
        return {
            map: null,
            ui: null,
            dragging: false,
            mapBehavior: null,
            showInfo: false,
            contextMenuVisible: false,
            currentCoordinates: null,
            contextCoordinates: null,
            markerStore: null,
            infoOnTop: false,
            infoFields: null,
            zoomLevel: null,
            zoomLevelTimer: null,
            zoomLevelDelay: 200,
            infoContent: [],
            polygons: [],
            polygonLines: [],
            polylineMapPoints: [],
            polygonLabels: [],
            markerLabels: [],
            videoRoutes: [],
            polylines: [],
            polylineLines: [],
            polylineLabels: [],
            polylineBubbles: [],
            markers: [],
            endCircles: [],
            importedObjects: [],
            timer: null,
            geoLabels: [],
            equipmentGroup: null,
            userMarkerId: 1,
            anchorPointsGroup: null,
            editablePolygonLineStrings: null,
            topMenu: '0px',
            leftMenu: '0px',
            cursorX: 0,
            cursorY: 0,
            mapCenter: {y: 62.244207, x: 25.748005},
            baseLayer: null,
        }
    },

    watch: {
        center: function () {
            if (this.center) {
                this.mapCenter = this.center
                if(this.mapCenter.y && this.mapCenter.x) {
                    this.zoomToPosition(this.mapCenter.y, this.mapCenter.x)
                } else if(this.mapCenter.lat && this.mapCenter.lng) {
                    this.zoomToPosition(this.mapCenter.lat, this.mapCenter.lng)
                }
            }
        }
    },

    mounted() {
        var ComponentClass = Vue.extend(MapMarkers)
        this.markerStore = new ComponentClass()
        this.markerStore.$mount()
        this.initMap()
        if (this.center) {
            this.mapCenter = this.center
            if(this.mapCenter.y && this.mapCenter.x) {
                this.zoomToPosition(this.mapCenter.y, this.mapCenter.x)
            } else if(this.mapCenter.lat && this.mapCenter.lng) {
                this.zoomToPosition(this.mapCenter.lat, this.mapCenter.lng)
            }
        }
        this.$emit('mounted', this.map)
        this.$nextTick(() => {
            this.showUserPosition()
        })
    },

    methods: {
        getMarkerStore() {
            return this.markerStore
        },
        initMap: function () {
            var platform = new H.service.Platform({
                'apikey': 'I5eyTN0wpktGB53r2lt4Lyp6KAyz4Ase6lbAIxW2NDw'
            })
            var defaultLayers = platform.createDefaultLayers()
            // Instantiate (and display) a map object:
            this.map = new H.Map(
                this.$refs['map-canvas'],
                defaultLayers.vector.normal.map,
                {
                    zoom: 12,
                    center: {lat: this.mapCenter.y, lng: this.mapCenter.x},
                    engineType: H.Map.EngineType.WEBGL
                }
            )
            this.ui = H.ui.UI.createDefault(this.map, defaultLayers)
            // Enable the event system on the map instance:
            var mapEvents = new H.mapevents.MapEvents(this.map)
            // Instantiate the default behavior, providing the mapEvents object:
            this.mapBehavior = new H.mapevents.Behavior(mapEvents)
            // Map event handling
            this.map.addEventListener('tap', this.mapClicked)
            // Long press is generated also on dragging - need to find other way for context menu in mobile devices
            this.map.addEventListener('longpress', this.mapLongPress)
            this.map.addEventListener('pointermove', this.pointerMove)
            this.map.addEventListener('mapviewchangeend', this.mapViewChange)
            this.checkStoredBaseLayer(defaultLayers)
            this.map.addEventListener('baselayerchange', this.baseLayerChange)
        },

        checkStoredBaseLayer: function(defaultLayers){
            let currentBaseKey = this.$store.state.mapSettings.here && this.$store.state.mapSettings.here.baseLayer
            for (let key in defaultLayers){
                if(defaultLayers[key].map) {
                    let copyKey = defaultLayers[key].map.getProvider().copyrightKey_
                    if(copyKey === currentBaseKey){
                        this.map.setBaseLayer(defaultLayers[key].map)
                    }
                }
            }
        },

        baseLayerChange: function() {
            const currentBaseLayer = this.map.getBaseLayer()
            this.baseLayer = currentBaseLayer.getProvider().copyrightKey_
            this.updateMapSettings({ here: { baseLayer: this.baseLayer } })
            this.saveMapSettings()
        },
        mousemove: function (event) {
            this.cursorX = event.clientX
            this.cursorY = event.clientY
        },
        touchmove: function (event) {
            this.cursorX = event.touches[0].clientX
            this.cursorY = event.touches[0].clientY
        },
        mapClicked: function (evt) {
            if (this.contextMenuVisible) {
                this.contextMenuVisible = false
            } else {
                let coord = this.map.screenToGeo(evt.currentPointer.viewportX,
                    evt.currentPointer.viewportY)
                this.$emit('onMapClicked', coord)
            }
        },

        mapLongPress: function (evt) {
            if (this.contextMenuVisible) {
                this.contextMenuVisible = false
            } else {
                this.currentCoordinates = this.map.screenToGeo(evt.currentPointer.viewportX,
                    evt.currentPointer.viewportY)
                this.openMenuXY(this.cursorX, this.cursorY)
            }
        },

        getMapCenter: function () {
          return this.map.getCenter()
        },

        getViewBounds() {
            return this.map.getViewModel().getLookAtData().bounds;
        },

        getViewBoundsCoordinates() {
            const bbox = this.getViewBounds().getBoundingBox();
            return [bbox.getLeft(), bbox.getBottom(), bbox.getRight(), bbox.getTop()]
        },

        getMapZoomLevel: function () {
          return this.map.getZoom()
        },

        openMenu: function (event) {
            if (!this.dragging) {
                this.openMenuXY(event.x, event.y)
                event.preventDefault()
            }
        },

        openMenuXY: function (x, y) {
            if (this.menuEnabled) {
                this.contextCoordinates = this.currentCoordinates
                this.contextMenuVisible = true
                Vue.nextTick(function () {
                    this.$refs['map-canvas'].focus()
                    this.showContextMenu(y, x)
                }.bind(this))
            }
        },

        showContextMenu: function (top, left) {
            let largestHeight = window.innerHeight - 240
            let largestWidth = window.innerWidth - 260
            if (top > largestHeight) {
                top = largestHeight
            }
            if (left > largestWidth) {
                left = largestWidth
            }
            this.topMenu = top + 'px'
            this.leftMenu = left + 'px'
        },

        pointerMove: function (evt) {
            this.currentCoordinates = this.map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY)
        },

        mapViewChange() {
            const prevZoomLevel = this.zoomLevel;
            const newZoomLevel = this.map.getZoom();

            if (prevZoomLevel !== newZoomLevel) {
                // Use a timer to delay the event emission in case of multiple zoom level changes
                clearTimeout(this.zoomLevelTimer);
                this.zoomLevelTimer = setTimeout(() => {
                    this.zoomLevel = newZoomLevel;
                    this.$emit('onZoomLevelChange', this.zoomLevel);
                }, this.zoomLevelDelay);
            } else {
                const bbox = this.getViewBoundsCoordinates()
                this.$emit('onBoundingBoxChange', bbox)
            }
        },

        hideContextMenu: function () {
            this.contextMenuVisible = false
        },

        copyCoordinates: function () {
            this.$clipboard(this.contextCoordinates.lat + ' ' + this.contextCoordinates.lng)
            this.hideContextMenu()
        },
        menuItemClicked(item) {
            if (!item.disabled) {
                item.onClick(this.contextCoordinates)
                this.hideContextMenu()
            }
        },

        zoomToPosition: function (lat, lon, zoom) {
            this.map.getViewPort().resize()
            this.map.setCenter({lat: lat, lng: lon})
            if (zoom !== undefined) {
                this.map.setZoom(zoom)
            }
        },

        /**
         * Zooms the view to show all the objects in the group
         * @param groupId to specify a group to add the map object to
         **/
        zoomToGroup: function(type) {
          if(this.markers[type] != null) {
            let group = new H.map.Group()
            let markers = []
            let keys = Object.keys(this.markers[type])
            for (var i = 0; i < keys.length; i++) {
              markers.push(this.markers[type][keys[i]])
            }
            group.addObjects(markers);
            this.map.addObject(group)
            this.map.getViewModel().setLookAtData({bounds: group.getBoundingBox()})
          }
        },

        resizeMap: function () {
            this.map.getViewPort().resize()
        },

        setMapZoomLevel: function (zoom) {
            this.map.setZoom(zoom)
        },

        showUserPosition: function () {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(pos => {
                    this.showPosition(pos)
                }, () => {
                }, {timeout: 30000, enableHighAccuracy: true})
            }
        },

        /** Zoom to user location and show user position marker */
        showPosition: function (position, removeMarker = true, zoomToPos = false) {
            if (position) {
                const { latitude, longitude } = position.coords;
                if (zoomToPos) {
                    this.zoomToPosition(latitude, longitude)
                }
                if (removeMarker) {
                    this.showUserMarker(latitude, longitude)
                }
            }
        },

        showUserMarker: function (lat, lon) {
            if (!lat || !lon) return
            this.removeMapMarker(this.userMarkerId, this.USER_LOCATION)
            this.showMapMarker(this.userMarkerId, this.USER_LOCATION, lat, lon, this.markerStore.getUserMarkerIcon(), false, 0)
        },

        addEquipmentMarkers: function (equipmentList) {
            this.equipmentGroup = new H.map.Group()
            this.map.addObject(this.equipmentGroup)
            equipmentList.forEach(function (equipment) {
                this.removeMapMarker(equipment.id, this.EQUIPMENT)
                this.equipmentMarkerInfo(equipment, this.markerStore.getEquipmentMarkerIcon())
            }.bind(this))
        },

        addEquipmentMarker: function (equipment) {
            this.showMapMarker(equipment.id, this.EQUIPMENT, equipment.location.y, equipment.location.x,
                this.markerStore.getEquipmentMarkerIcon(), false)
            this.setMapZoomLevel(16)
            this.zoomToPosition(equipment.location.y, equipment.location.x)
        },

        addDraggableEquipmentMarker: function (equipment) {
            this.showMapMarker(equipment.id, this.EQUIPMENT, equipment.location.y, equipment.location.x,
                this.markerStore.getEquipmentMarkerIcon(), true)
            this.setMapZoomLevel(16)
            this.zoomToPosition(equipment.location.y, equipment.location.x)
        },

        equipmentMarkerInfo: function (equipment, iconElement) {
            let icon = new H.map.DomIcon(iconElement, {anchor: {y: equipment.location.y, x: equipment.location.x}})
            let marker = new H.map.DomMarker({lat: equipment.location.y, lng: equipment.location.x}, {
                icon: icon
            })
            marker.setZIndex(5)
            this.equipmentGroup.addObject(marker)
            let manufacturer = equipment.manufacturer ? equipment.manufacturer : '-'
            let description = equipment.description ? equipment.description : '-'
            let bubble = new H.ui.InfoBubble(new H.geo.Point(equipment.location.y, equipment.location.x), {
                content:
                    '<div>' +
                    '<div class="bubble-text">' + equipment.name + '</div>' +
                    '<div><div class="col-sm-12  nopads bubble-title">' + this.$t('equipment.manufacturer') + ':</div><div class="col-sm-12 bubble-content-text nopads">' + manufacturer + '</div></div>' +
                    '<div><div class="col-sm-12  nopads bubble-title">' + this.$t('equipment.description') + ':</div><div class="col-sm-12 bubble-content-text nopads">' + description + '</div></div>' +
                    '</div>'
            })
            bubble.addClass('map-equipment-box')
            this.ui.addBubble(bubble)
            bubble.close()

            marker.addEventListener('tap', function () {
                bubble.open()
            }, false)
        },

        /**
         * Draws a polyline on the map. The Id given needs to be unique,
         * e.g. from database or some random for others
         * @param id a unique id for the polyline
         * @param type integer that refers to the group of polylines, used e.g. in toggling visibility         * @param points coordinates for the line in format [{x: 25, y: 62}]
         * @param points coordinates for the line in format [{x: 25, y: 62}]
         * @param color of the line
         * @param dash use dashed line
         * @param arrows
         * @param lineWidth
         * @param transparency
         **/
        drawPolyLine: function (id, type, points, color, dash = false, arrows = false, lineWidth = 3,
                                transparency = 1, lineStartPoint = false, closed = false, externalId = null, pointHover = false) {
            // first remove old if exists
            this.removePolyline(id, type)
            if (!this.polylines[type]) {
                this.polylines[type] = []
            }
            let rgb = this.hexToRgb(color)
            let lineColor = 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', ' + transparency + ')'
            if (points && points.length > 0) {
                var line = points.reduce((line, point) => {
                    line.pushLatLngAlt(point.y, point.x, 10) // 10 is just any alt as we do not get alt for now
                    return line
                }, new H.geo.LineString())
                let style = {lineWidth: lineWidth, strokeColor: lineColor}
                if (arrows) {
                    style.lineHeadCap = "arrow-head"
                } else if (dash) {
                    style.lineDash = [5]
                }
                var polyline = new H.map.Polyline(line, {
                    style: style
                })
                polyline.setData(externalId ? {id: externalId, type: type} : {id: id, type: type})
                polyline.addEventListener('pointerenter', this.polylineHoverEvent)
                polyline.addEventListener('pointerleave', this.polylineHoverExitEvent)
                polyline.addEventListener('tap', this.polylineTapEvent)
                polyline.setZIndex(3)
                this.map.addObject(polyline)
                this.polylines[type][id] = polyline
                if (lineStartPoint) {
                    this.showPolylineStartPoint(id, type, points, closed)
                }
                if (pointHover) {
                    this.addPointHovering(points)
                }
            }
        },

        addPointHovering(points) {
            // Add invisible hoverable points for each point in the polyline
            points.forEach((point, index) => {
                // Create a transparent icon for the marker
                let invisibleSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"></svg>`;
                let invisibleIcon = new H.map.Icon(invisibleSvg); // Invisible 1x1 SVG icon
                // Create a marker with the invisible icon
                // let marker = new H.map.Marker({ lat: point.y, lng: point.x });
                let marker = new H.map.Marker({ lat: point.y, lng: point.x }, {
                    icon: invisibleIcon // Set the invisible icon
                });
                // Set marker data for interaction
                marker.setData({ pointPosition: index });
                // Add hover event listeners
                marker.addEventListener('pointerenter', this.pointHoverEvent);
                marker.addEventListener('pointerleave', this.pointHoverExitEvent);
                marker.setZIndex(5); // Ensure hit areas are on top

                // Add the marker to the map
                this.map.addObject(marker);
            });
        },


        pointHoverEvent: function (e) {
            let pointData = e.target.getData();
            this.$emit('onPointHover', pointData)
        },

        pointHoverExitEvent: function (e) {
            let pointData = e.target.getData();
            this.$emit('onPointHoverExit', pointData)
        },

        showPolylineStartPoint: function (id, type, points, closed = false) {
            if (!this.polylineMapPoints[type]) {
                this.polylineMapPoints[type] = []
            }
            if (!this.polylineMapPoints[type][id]) {
                this.polylineMapPoints[type][id] = []
            }
            if (points) {
                let icon = new H.map.DomIcon(closed ? this.markerStore.getPolylinePointClosedIcon() : this.markerStore.getPolylinePointIcon(), {anchor: {y: points[0].y, x: points[0].x}})
                let marker = new H.map.DomMarker({lat: points[0].y, lng: points[0].x}, {
                    icon: icon
                })
                marker.draggable = false
                marker.addEventListener('tap', this.markerTapEvent)
                marker.addEventListener('pointerenter', this.markerPointerEnterEvent)
                marker.addEventListener('pointerleave', this.markerPointerLeaveEvent)
                marker.setData({id: id, type: type})
                marker.setZIndex(5)
                this.map.addObject(marker)
                this.polylineMapPoints[type][id] = marker
            }
        },

        removePolylinePoints: function (type, id) {
            if (type && this.polylineMapPoints[type] && id && this.polylineMapPoints[type[id]]) {
                this.map.removeObject(this.polylineMapPoints[type][id])
                this.polylineMapPoints[type][id] = []
            }
        },

        hidePolylineBubble: function (id, type) {
            if (this.polylineBubbles[type] && this.polylineBubbles[type][id]) {
                this.polylineBubbles[type][id].close()
                this.polylineBubbles[type][id] = undefined
            }
        },

        showPolylineBubble: function (id, type, posY, posX, title, values) {
            if (!this.polylineBubbles[type] || !this.polylineBubbles[type][id] || this.polylineBubbles[type][id].getState() === 'closed') {
                if (!this.polylineBubbles[type]) {
                    this.polylineBubbles[type] = []
                }
                this.polylineBubbles[type][id] = null
                let content = '<div>' + '<div class="bubble-text">' + title + '</div>'
                values.forEach(item => {
                    content += '<div><div class="col-sm-12  nopads bubble-title">' + item.name + ':</div><div class="col-sm-12 bubble-content-text nopads">' + item.value + '</div></div>'
                })
                content += '</div>'
                let bubble = new H.ui.InfoBubble(new H.geo.Point(posY, posX), {
                    content: content
                })
                bubble.addClass('map-equipment-box')
                this.ui.addBubble(bubble)
                this.polylineBubbles[type][id] = bubble
                bubble.open()
            }
        },


        /**
         * Removes a polyline from the map
         * @param id of the line to be removed
         * @param type integer that refers to the group of polylines, used e.g. in toggling visibility
         **/
        removePolyline: function (id, type) {
            if (this.polylines[type]) {
                var polyline = this.polylines[type][id]
                if (polyline) {
                    this.map.removeObject(polyline)
                    this.polylines[type][id] = undefined
                }
            }else if (this.polylineLines[id] !== undefined) {
                this.map.removeObject(this.polylineLines[id])
                this.polylineLines[id] = undefined
            }
            this.removePolylinePoints(type, id)
        },

        removePolylinesByType: function (type) {
            if (type && this.polylines[type]) {
                this.polylines[type].forEach(function (polyline) {
                    this.map.removeObject(polyline)
                }, this)
                this.polylines[type] = []
            }
            this.removePolylineStartPointsByType(type)

        },

        removePolylineStartPointsByType: function (type) {
            if (this.polylineMapPoints[type]) {
                this.polylineMapPoints[type].forEach(function (point) {
                    this.map.removeObject(point)
                }, this)
                this.polylineMapPoints[type] = []
            }
        },

        removePolygonsByType: function (type) {
            if (type && this.polygons[type]) {
                this.polygons[type].forEach(function (polygon) {
                    this.map.removeObject(polygon)
                }, this)
                this.polygons[type] = []
            }
        },

        removeMapItemsByType: function (type) {
            this.removePolylinesByType(type)
            this.removePolygonsByType(type)
            this.removeMapMarkerByType(type)
        },

        /**
         * Emits a hover event with the id of the hovered polyline
         * @param e contains the data of the polyline, usually id of the polyline
         **/
        polylineHoverEvent: function (e) {
            this.$emit('onPolylineHover', e.target.getData())
        },

        /**
         * Emits a hover exit event
         **/
        polylineHoverExitEvent: function (e) {
            this.$emit('onPolylineHoverExit', e.target.getData())
        },

        /**
         * Emits a tap event with the id of the tapped polyline
         * @param e contains the data of the polyline, usually id of the polyline
         **/
        polylineTapEvent: function (e) {
            this.$emit('onPolylineTap', e.target.getData())
        },

        /**
         * Shows map info view (table) on top of the map with given fields and content
         * @param infoFields are the table headers
         * @param infoContent is the data for the table
         **/
        showMapInfo: function (infoFields, infoContent, onTop) {
            this.infoFields = infoFields
            this.infoContent = infoContent
            this.showInfo = true
            this.infoOnTop = onTop
        },

        /**
         * Hides the map info view and sends callback
         **/
        hideMapInfoCallback: function () {
            this.hideMapInfo()
            this.$emit('onMapInfoClosed')
        },

        /**
         * Hides the map info view
         **/
        hideMapInfo: function () {
            this.infoFields = []
            this.infoContent = []
            this.showInfo = false
        },

        /**
         * Creates a new area polygon and ads it on the map
         * @param id is a unique identifier for the polygon, e.g. database id
         * @param lat latitude coordinate
         * @param lng longitude coordinate
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         **/
        newPolygon: function (id, lat, lng, type = this.AREA) {
            // Create polygon line
            var line = new H.geo.LineString()
            line.pushLatLngAlt(lat, lng, 10) // 10 is just some altitude as it's mandatory
            if(!this.polygonLines[type]) this.polygonLines[type] = []
            this.polygonLines[type][id] = line
            // Create polygon
            var polygon = new H.map.Polygon(line, {
                style: {
                    strokeColor: '#829',
                    fillColor: 'rgba(107, 107, 107, 0.5)',
                    lineWidth: 4
                }
            })
            if(!this.polygons[type]) this.polygons[type] = []
            this.polygons[type][id] = polygon
            // Draw polygon on map
            this.map.addObject(polygon)
        },

        /**
         * Adds a point to a polygon. The added point will be the last point.
         * @param id is a unique identifier for the polygon, e.g. database id
         * @param lat latitude coordinate
         * @param lng longitude coordinate
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         **/
        addPointToPolygon: function (id, lat, lng, type = this.AREA) {
            // Find polygon line
            if(!this.polygonLines[type]) this.polygonLines[type] = []
            var line = this.polygonLines[type][id]
            if (!line) {
                return
            }

            // Find polygon
            var polygon = this.polygons[type][id]
            this.map.removeObject(polygon)

            // Add point to polygon data and create new polygon
            line.pushLatLngAlt(lat, lng, 10)
            polygon = new H.map.Polygon(line, {
                style: {
                    strokeColor: '#829',
                    lineWidth: 3
                }
            })
            // Update polygon item in polygon array
            if(!this.polygons[type]) this.polygons[type] = []
            this.polygons[type][id] = polygon
            // Add new polygon to map
            this.map.addObject(polygon)
        },

        /**
         * Creates a new polyline and adds it on the map
         * @param id is a unique identifier for the polyline, e.g. database id
         * @param lat latitude coordinate
         * @param lng longitude coordinate
         **/
        newPolyLine: function (id, lat, lng) {
            let lineString = new H.geo.LineString();
            lineString.pushPoint({lat:lat, lng:lng}, 10);
            this.polylines[id] = lineString
        },

        /**
         * Adds a point to a polyline. The added point will be the last point.
         * @param id is a unique identifier for the polyline, e.g. database id
         * @param lat latitude coordinate
         * @param lng longitude coordinate
         **/
        addPointToPolyLine: function (id, lat, lng) {
            let line = this.polylines[id]
            line.pushPoint({lat:lat, lng:lng}, 10)
            if(this.polylineLines[id]){
                let polyline = this.polylineLines[id]
                this.map.removeObject(polyline)
            }
            let polyline = new H.map.Polyline(line, {
                style: {
                    strokeColor: '#829',
                    lineWidth: 3
                }
            })
            this.polylineLines[id] = polyline
            this.polylines[id] = line
            this.map.addObject(polyline)
        },

        /**
         * Removes the last point from a polygon.
         * @param id is a unique identifier for the polygon, e.g. database id
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         **/
        removeLastAddedPointFromPolygon: function (id, type = this.AREA) {
            // Find polygon line
            var line = this.polygonLines[type][id]
            if (!line) {
                return
            }
            line = this.removeLastPoint(line)
            let polygon = this.polygons[type][id]
            this.map.removeObject(polygon)
            this.polygonLines[type][id] = line
            polygon = new H.map.Polygon(line, {
                style: {
                    strokeColor: '#829',
                    lineWidth: 3
                }
            })
            // Update polygon item in polygon array
            if(!this.polygons[type]) this.polygons[type] = []
            this.polygons[type][id] = polygon
            if (line.getPointCount() === 0 ) return
            // Add new polygon to map
            this.map.addObject(polygon)
        },

        /**
         * Removes the last point from a polygon.
         * @param id is a unique identifier for the polygon, e.g. database id
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         **/
        removeLastPointFromPolygon: function (id, type = this.AREA) {
            // Find polygon line
            var line = this.polygonLines[type][id]
            if (!line) {
                return
            }
            if (line.getPointCount() > 1) {
                var polygon = this.polygons[type][id]
                this.map.removeObject(polygon)
                line = this.removeLastPoint(line)
                this.polygonLines[type][id] = line
                polygon = new H.map.Polygon(line, {
                    style: {
                        strokeColor: '#829',
                        lineWidth: 3
                    }
                })
                // Update polygon item in polygon array
                if(!this.polygons[type]) this.polygons[type] = []
                this.polygons[type][id] = polygon
                // Add new polygon to map
                this.map.addObject(polygon)
            }
        },

        /**
         * Removes the last point from a polyline.
         * @param id is a unique identifier for the polyline, e.g. database id
         **/
        removeLastPointFromPolyline: function (id) {
            // Find polyline line
            let line = this.polylines[id]
            line = this.removeLastPoint(line)
            let polyline = this.polylineLines[id]
            if (!line) {
                return
            }
            if(polyline){
                this.map.removeObject(polyline)
            }
            if(line.getPointCount() > 1){
                polyline = new H.map.Polyline(line, {
                    style: {
                        strokeColor: '#829',
                        lineWidth: 3
                    }
                })
                this.map.addObject(polyline)
            }else{
                polyline = null
            }
            // Update polyline item in polyline array
            this.polylines[id] = line
            this.polylineLines[id] = polyline
        },

        removeLastPoint: function (line) {
            var result = new H.geo.LineString()
            line.eachLatLngAlt(function (lat, lng, alt) {
                result.pushLatLngAlt(lat, lng, alt)
            }, 0, line.getPointCount() - 1)
            return result
        },

        /**
         * Adds a complete polygon to the map
         * @param id is a unique identifier for the polygon, e.g. database id
         * @param type
         * @param points are the coordinates in format { lng, lat } for each point
         * @param stroke
         * @param fill
         * @param transparency
         * @param linewidth
         **/
        drawPolygon: function (id, points, type = this.AREA, stroke = '#153140', fill='#b0cddc', transparency=0.3, linewidth=1) {
            let rgb = this.hexToRgb(fill)
            let fillColor = 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', ' + transparency + ')'
            var line = points[0].reduce((line, point) => {
                line.pushLatLngAlt(point[1], point[0], 10) // 10 is just any alt as we do not get alt for now
                return line
            }, new H.geo.LineString())
            if(!this.polygonLines[type]) this.polygonLines[type] = []
            this.polygonLines[type][id] = line
            var polygon = new H.map.Polygon(line, {
                style: {
                    strokeColor: stroke,
                    fillColor: fillColor,
                    lineWidth: linewidth
                }
            })
            if(!this.polygons[type]) this.polygons[type] = []
            this.polygons[type][id] = polygon
            this.map.addObject(polygon)
        },

        /**
         * Store area lines temporarily
         * @param polygonIndex is a unique identifier for the polygon
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         **/
        storeAreaInfo: function (polygonIndex, type = this.AREA) {
            let linestring = []
            if(!this.polygonLines[type]){
                this.polygonLines[type] = []
            }
            let line = this.polygonLines[type][polygonIndex]
            if (line) {
                line.eachLatLngAlt(function (lat, lng) {
                    linestring.push([lng, lat])
                })
            }
            return linestring
        },

        /**
         * Remove feature resize points
         **/
        removeAnchorPoints: function () {
            if (this.anchorPointsGroup !== null) {
                this.map.removeObject(this.anchorPointsGroup)
                this.anchorPointsGroup = null
            }
        },

        /**
         * Add area resize points
         * @param polygonId is a unique identifier for the polygon
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         * @param anchorPointsEnabled whether polygon has already anchorpoints for editing
         **/
        addAnchorPoints: function (polygonId, type = this.AREA, anchorPointsEnabled = false) {
            this.removeAnchorPoints()
            // Create a group that can hold map objects
            this.anchorPointsGroup = new H.map.Group()
            // Add the group to the map object
            this.map.addObject(this.anchorPointsGroup)

            // Here draws automatically close line to polygon, this creates one extra point
            if(!anchorPointsEnabled){
                this.removeLastPointFromPolygon(polygonId, type)
            }

            let icon = new H.map.DomIcon(this.markerStore.getDragAnchorMarkerIcon())

            this.polygonLines[type][polygonId].eachLatLngAlt((lat, lng, alt, i) => {
                var marker = new H.map.DomMarker({lat: lat, lng: lng}, {icon: icon})
                // Ensure that the marker can receive drag events
                marker.draggable = true
                marker.setData({polygon: polygonId, point: i})
                this.anchorPointsGroup.addObject(marker)

                // disable the default draggability of the underlying map
                // when starting to drag a marker object:
                marker.addEventListener('dragstart', function () {
                    this.mapBehavior.disable()
                }.bind(this))

                // re-enable the default draggability of the underlying map
                // when dragging has completed
                marker.addEventListener('dragend', function () {
                    this.mapBehavior.enable()
                }.bind(this))

                marker.addEventListener('drag', function (ev) {
                    let pointer = ev.currentPointer
                    let position = this.map.screenToGeo(pointer.viewportX, pointer.viewportY)
                    ev.target.setGeometry(position)
                    this.movePoint(ev, position, type)
                }.bind(this))
            })
        },

        /**
         * Add polyline resize points
         * @param polylineId is a unique identifier for the polyline
         **/
        addPolylineAnchorPoints: function (polylineId) {
            this.removeAnchorPoints()
            // Create a group that can hold map objects
            this.anchorPointsGroup = new H.map.Group()
            // Add the group to the map object
            this.map.addObject(this.anchorPointsGroup)
            let icon = new H.map.DomIcon(this.markerStore.getDragAnchorMarkerIcon())
            let headIcon = new H.map.DomIcon(this.markerStore.getDragAnchorHeadMarkerIcon())
            let polylinesLength = this.polylines[polylineId].getPointCount()
            this.polylines[polylineId].eachLatLngAlt((lat, lng, alt, i) => {
                var marker = new H.map.DomMarker({lat: lat, lng: lng}, {icon: i + 1 === polylinesLength ? headIcon : icon})
                // Ensure that the marker can receive drag events
                marker.draggable = true
                marker.setData({polyline: polylineId, point: i})
                this.anchorPointsGroup.addObject(marker)
                // disable the default draggability of the underlying map
                // when starting to drag a marker object:
                marker.addEventListener('dragstart', function () {
                    this.mapBehavior.disable()
                }.bind(this))
                // re-enable the default draggability of the underlying map
                // when dragging has completed
                marker.addEventListener('dragend', function () {
                    this.mapBehavior.enable()
                }.bind(this))
                marker.addEventListener('drag', function (ev) {
                    let pointer = ev.currentPointer
                    let position = this.map.screenToGeo(pointer.viewportX, pointer.viewportY)
                    ev.target.setGeometry(position)
                    this.movePolylinePoint(ev, position)
                }.bind(this))
            })
        },

        /**
         * Emits a tap event with the id of the tapped area
         * @param ev, clicked area
         **/
        areaTapEvent: function (ev) {
            if (!this.isEditing) {
                let polygonId = -1
                this.polygons.forEach(type => {
                    polygonId = type.findIndex(polygon => polygon === ev.target)
                })
                this.polygons.forEach(type => {
                    type.forEach((polygon, id) => {
                        if (polygon) {
                            if (id === polygonId) {
                                polygon.setStyle({
                                    strokeColor: polygon.style.strokeColor,
                                    fillColor: polygon.style.fillColor,
                                    lineWidth: 4
                                })
                            } else {
                                polygon.setStyle({
                                    strokeColor: polygon.style.strokeColor,
                                    fillColor: polygon.style.fillColor,
                                    lineWidth: 2
                                })
                            }
                        }
                    })
                })
                this.$emit('editAreaEvent', polygonId)
            }
        },

        /**
         * Store original area lines and set anchor points
         * @param polygonId is a unique identifier for the polygon
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         * @param anchorPointsEnabled whether polygon has already anchorpoints for editing
         **/
        editArea: function (polygonId, type = this.AREA, anchorPointsEnabled = false) {
            if (!this.isEditing) {
                this.editablePolygonLineStrings = this.storeAreaInfo(polygonId, type)
                this.addAnchorPoints(polygonId, type, anchorPointsEnabled)
            }
        },

        /**
         * Store original lines and set anchor points
         * @param polylineId is a unique identifier for the polyline
         **/
        editPolyline: function (polylineId) {
            if (!this.isEditing) {
                this.editablePolylineStrings = this.storeAreaInfo(polylineId)
                this.addPolylineAnchorPoints(polylineId)
            }
        },

        /**
         * Move area points
         * @param ev, clicked area
         * @param position, point position
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         **/
        movePoint: function (ev, position, type = this.AREA) {
            let PointData = ev.target.getData()
            let line = this.polygonLines[type][PointData.polygon]
            let polygon = this.polygons[type][PointData.polygon]
            this.map.removeObject(polygon)
            line.removeLatLngAlt(PointData.point * 3)
            line.insertLatLngAlt(PointData.point * 3, position.lat, position.lng, 10)
            polygon = new H.map.Polygon(line, {
                style: {
                    strokeColor: '#829',
                    lineWidth: 3
                }
            })
            this.polygons[type][PointData.polygon] = polygon
            this.map.addObject(polygon)
        },

        /**
         * Move polyline points
         * @param ev, clicked area
         * @param position, point position
         **/
        movePolylinePoint: function (ev, position) {
            let PointData = ev.target.getData()
            let line = this.polylines[PointData.polyline]
            this.map.removeObject(this.polylineLines[PointData.polyline])
            line.removeLatLngAlt(PointData.point * 3)
            line.insertLatLngAlt(PointData.point * 3, position.lat, position.lng, 10)
            let polyline = new H.map.Polyline(line, {
                style: {
                    strokeColor: '#829',
                    lineWidth: 3
                }
            })
            this.polylineLines[PointData.polyline] = polyline
            this.map.addObject(polyline)
        },

        /**
         *  Disable area editing
         * @param polygonId is a unique identifier for the polygon
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         **/
        disableAreaEditing: function (polygonId, type = this.AREA) {
            this.removeAnchorPoints()
            this.removePolygon(polygonId, type)
            this.drawPolygon(polygonId, this.editablePolygonLineStrings, type)
            this.polygons[type][polygonId].addEventListener('tap', this.areaTapEvent)
            this.editablePolygonLineStrings = null
        },

        /**
         * Removes a polygon by id
         * @param id of the polygon to be removed
         * @param type number used for grouping polygons, e.g. in toggling visibility
         */
        removePolygon: function (id, type = this.AREA) {
            if (this.polygons[type] !== undefined) {
                if (this.polygons[type][id] !== undefined) {
                    // Remove polygon if exists
                    this.map.removeObject(this.polygons[type][id])
                    this.polygons[type][id] = undefined
                    // Remove line if exists
                    if(this.polygonLines[type] && this.polygonLines[type][id]){
                        this.polygonLines[type][id] = undefined
                    }
                    // Remove label if exists
                    if (this.polygonLabels[type] && this.polygonLabels[type][id]) {
                        this.ui.removeBubble(this.polygonLabels[type][id])
                        this.polygonLabels[type][id] = undefined
                    }
                }
            }
        },

        /**
         * Adds an info bubble with text for a polygon
         * @param id of the polygon
         * @param label is the text to be shown in the info bubble
         * @param lat coordinate of the bubble anchor
         * @param lng coordinate of the bubble anchor
         */
        addMarkerLabel: function (id, label, lat, lng) {
            var bubble = new H.ui.InfoBubble(new H.geo.Point(lat, lng), {
                content: '<span class="bubble-text" style="padding-right: 2em">' + label + '</span>'
            })
            bubble.addClass('map-info-box')
            this.markerLabels[id] = bubble
            this.ui.addBubble(bubble)
            bubble.open()
        },
        addPolylineEndIndicators(mapObject, color) {
            let points = mapObject.getGeometry();
            const pointsArray = [];
            points.eachLatLngAlt(function(lat, lng) {
                // Add the latitude and longitude to the array
                pointsArray.push(new H.geo.Point(lat, lng));
            });

            let solidCircleIcon = this.markerStore.getSolidCircleIcon()
            let iconDiv = this.markerStore.getAsDivElement(solidCircleIcon)
            this.markerStore.setIconStyleProperty(iconDiv, 'color', color)
            this.markerStore.setIconStyleProperty(iconDiv, 'marginTop', '-10px')
            this.markerStore.setIconStyleProperty(iconDiv, 'marginLeft', '-5px')
            solidCircleIcon = iconDiv.outerHTML
            let icon = new H.map.DomIcon(solidCircleIcon)

            // Define the center of the circle
            const startCenter = { lat: pointsArray[0].lat, lng: pointsArray[0].lng };
            const endCenter = { lat: pointsArray[pointsArray.length - 1].lat, lng: pointsArray[pointsArray.length - 1].lng };

            // Create a new DomMarker object using the DomIcon
            const startMarker = new H.map.DomMarker(startCenter, { icon: icon });
            const endMarker = new H.map.DomMarker(endCenter, { icon: icon });

            this.endCircles.push(startMarker)
            this.endCircles.push(endMarker)
            this.map.addObject(startMarker)
            this.map.addObject(endMarker)
        },
        removeAllLineEndIndicators() {
            if(this.endCircles && this.endCircles.length > 0) {
                for (let i = 0; i < this.endCircles.length; i++) {
                    this.map.removeObject(this.endCircles[i])
                }
                this.endCircles = []
            }
        },
        initGeoJsonReader : function (linecolor = '#B200FF', addLineEndIndicators = false, transparency = 1, lineWidth = 3){
            // Create GeoJSON reader which will download the specified file.
            // Shape of the file was obtained by using HERE Geocoder API.
            // It is possible to customize look and feel of the objects.
            let rgb = this.hexToRgb(linecolor)
            linecolor = 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', ' + transparency + ')'
            return new H.data.geojson.Reader('', {
                disableLegacyMode: true,
                // This function is called each time parser detects a new map object
                style: function (mapObject) {
                    // Parsed geo objects could be styled using setStyle method
                    if (mapObject instanceof H.map.Polygon) {
                        // set polygon style depending on if it is selected or not
                        mapObject.setStyle({
                            fillColor: this.mapObjectSelected(mapObject) ? '#ff0000' : linecolor,
                            strokeColor: this.mapObjectSelected(mapObject) ? '#ff0000' : linecolor,
                            lineWidth: lineWidth
                        })
                    } else if (mapObject instanceof H.map.Marker) {
                        //let svgMarkup = '<svg height="100" width="100"><circle cx="50" cy="50" r="40" stroke="black" stroke-width="3"/></svg>'
                        // set markerColor style depending on if it is selected or not
                        let markerColor = this.mapObjectSelected(mapObject) ? '#ff0000' : linecolor
                        let svgMarkup = '<svg width="16" height="16" ' +
                            'xmlns="http://www.w3.org/2000/svg">' +
                            '<circle cx="8" cy="8" r="6" stroke="black" stroke-width="1" fill="'+markerColor+'" />' +
                            '</svg>'
                        mapObject.setIcon(new H.map.Icon(svgMarkup, {anchor: {x:8, y:8}}))
                    } else if (mapObject instanceof H.map.Polyline) {
                        // set polyline style depending on if it is selected or not
                        mapObject.setStyle({
                            fillColor: this.mapObjectSelected(mapObject) ? '#ff0000' : linecolor,
                            strokeColor: this.mapObjectSelected(mapObject) ? '#ff0000' : linecolor,
                            lineWidth: lineWidth
                        })
                        if(addLineEndIndicators) {
                            this.addPolylineEndIndicators(mapObject, this.mapObjectSelected(mapObject) ? '#ff0000' : linecolor)
                        }
                    }
                }.bind(this)
            })
        },

        mapObjectSelected(mapObject) {
            return mapObject.getData() && mapObject.getData().selected === true
        },

        addGeoLabel(lat, lon, text, collectionKey) {
            if (!this.geoLabels[collectionKey]) {
                this.geoLabels[collectionKey] = []
            }
            const contentCode = '<span style="color: #404041; font-weight: bold">'+text+'</span>'
            var bubble = new H.ui.InfoBubble(new H.geo.Point(lat, lon), {
                content: contentCode
            })
            bubble.addClass('map-info-box')
            this.ui.addBubble(bubble)
            bubble.open()
            bubble.setDisabled(true)
            this.geoLabels[collectionKey].push(bubble)
        },

        removeGeoLabels(collectionKey) {
            if (this.geoLabels[collectionKey]) {
                this.geoLabels[collectionKey].forEach(bubble => this.ui.removeBubble(bubble))
            }
            this.geoLabels[collectionKey] = []
        },

        removeSingleGeoLabel(collectionKey, text) {
            if (this.geoLabels[collectionKey][text]) {
                this.geoLabels[collectionKey][text].forEach(bubble => this.ui.removeBubble(bubble))
            }
            this.geoLabels[collectionKey][text] = []
        },

        removeLastGeoJsonObject() {
            if (this.importedObjects.length > 0) {
                let popped = this.importedObjects.pop()
                this.map.removeObject(popped)
            }
        },

        removeGeoJsonObject(index) {
            if (this.importedObjects.length > 0) {
                let removed = this.importedObjects[index]
                this.importedObjects.splice(index, 1)
                this.map.removeObject(removed)
            }
        },

        addGeoJsonObjects: function (geoJson, focusOnLayer = true, color = "#B200FF", addLineEndIndicators = false, transparency = 1, lineWidth = 3){
            let reader = this.initGeoJsonReader(color, addLineEndIndicators, transparency, lineWidth)
            reader.parseData(geoJson)
            this.importedObjects.push(new H.map.Group())
            this.importedObjects[this.importedObjects.length-1].addObjects(reader.getParsedObjects())
            this.importedObjects[this.importedObjects.length-1].setZIndex(1)
            this.importedObjects[this.importedObjects.length-1].setZIndex(1)
            this.map.addObject(this.importedObjects[this.importedObjects.length-1])
            if(focusOnLayer) {
                this.map.getViewModel().setLookAtData({bounds: this.importedObjects[this.importedObjects.length-1].getBoundingBox()})
            }
            // add event listener for every map object
            this.importedObjects[this.importedObjects.length-1].forEach(geometry => {
                geometry.getObjects().forEach((item) => {
                    // set handleGeoJson events so that items return proper ids and indexes when clicked
                    let itemId = this.checkProperties(item, 'id') ? item.getData().id : null
                    let importedItemIndex = this.checkProperties(item, 'importedItemIndex') ? item.getData().importedItemIndex : null
                    if(typeof itemId !== 'undefined' && itemId != null && typeof importedItemIndex !== 'undefined' && importedItemIndex != null) {
                        item.addEventListener('tap', () => this.handleGeoJsonEvents(item, itemId, importedItemIndex))
                        item.addEventListener('pointerenter', () => this.handleGeoJsonHoverEvent(item.getData(), itemId, importedItemIndex))
                        item.addEventListener('pointerleave', () => this.handleGeoJsonHoverExitEvent(item.getData(), itemId, importedItemIndex))
                    } else {
                        item.addEventListener('tap', () => this.handleGeoJsonEvents(item))
                        item.addEventListener('pointerenter', () => this.handleGeoJsonHoverEvent(item.getData(), null, null))
                        item.addEventListener('pointerleave', () => this.handleGeoJsonHoverExitEvent(item.getData()))
                    }
                })
            })
        },

        checkProperties(item,property) {
            return item.getData() && item.getData().hasOwnProperty(property)
        },

        handleGeoJsonEvents(item, index, importedItemIndex) {
            this.$emit('onGeoJsonClicked', null, item, index, importedItemIndex)
        },

        handleGeoJsonHoverEvent(item, index, importedItemIndex) {
            this.$emit('onGeoJsonHoverEvent', null, item, index, importedItemIndex, this.currentCoordinates)
        },

        handleGeoJsonHoverExitEvent(item, index, importedItemIndex) {
            this.$emit('onGeoJsonHoverExitEvent', null, item, index, importedItemIndex)
        },

        hideGeoJsonObjects: function () {
            this.importedObjects.forEach(item => this.map.removeObject(item))
            this.importedObjects = []
        },

        /**
         * Hides an info bubble with text
         * @param id of the polygon
         */
        hideMarkerLabel: function (id) {
            this.ui.removeBubble(this.markerLabels[id])
            this.markerLabels[id] = undefined
        },

        /**
         * Hides an info bubble with text
         * @param id of the polygon
         * @param label is the text to be shown in the info bubble
         * @param lat coordinate of the bubble anchor
         * @param lng coordinate of the bubble anchor
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         */
        addAreaLabel: function (id, label, lat, lng, type = this.AREA) {
            var bubble = new H.ui.InfoBubble(new H.geo.Point(lat, lng), {
                content: '<span class="bubble-text">' + label + '</span>'
            })
            bubble.addClass('map-info-box')
            if(!this.polygonLabels[type]) this.polygonLabels[type] = []
            this.polygonLabels[type][id] = bubble
            this.ui.addBubble(bubble)
            bubble.open()
        },

        showInfoBubbleWithoutItem(title, content, lat, lng) {
            let contentCode = '<span class="bubble-title">' + title + '</span>'
            if (content) {
                if (content !== null && content instanceof Object) {
                    contentCode += '<table>'
                    for (var key in content) {
                        contentCode += '<tr><td class="bubble-sub-title">' + key + '</td></tr><tr><td class="bubble-text bubble-text-wide">' + content[key] + '</td></tr>'
                    }
                    contentCode += '</table>'
                } else {
                    contentCode += '<span class="bubble-text">' + content + '</span>'
                }
            }

            var bubble = new H.ui.InfoBubble(new H.geo.Point(lat, lng), {
                content: contentCode
            })
            bubble.addClass('map-info-box')
            this.ui.addBubble(bubble)
            bubble.open()
        },

        showInfoBubble: function (type, id, title, content, lat, lng) {
            if (this.markers[type]) {
                let marker = this.markers[type][id]
                if (marker) {
                    this.showInfoBubbleWithoutItem(title, content, lat, lng)
                }
            }
        },

        /**
         * Returns the coordinates of a polygon
         * @param id
         * @param type integer that refers to the group of polygons, used e.g. in toggling visibility
         */
        getPolygonBoundaries: function (id, type = this.AREA) {
            // Find polygon line
            var linestring = []
            var line = this.polygonLines[type][id]
            if (line) {
                line.eachLatLngAlt(function (lat, lng) {
                    linestring.push({'lng': lng, 'lat': lat})
                })
            }
            return linestring
        },

        /**
         * Emits a tap event with the id of the tapped marker
         * @param id of the marker that was tapped
         **/
        async markerTapEvent(e) {
            const { viewportX: x, viewportY: y } = e.currentPointer;
            const markers = await new Promise(resolve => this.map.getObjectsAt(x, y, resolve));
            if (markers.length <= 1) {
                this.$emit('onMarkerTap', e.target.getData())
            }
            else {
                // Markers can have duplicate entries for example when it has both an icon and some geometry
                let dedupe = (data) => {
                    const elements = {};
                    data.forEach(el => elements[`${el.type}_${el.id}`] = el);
                    return Object.values(elements);
                };
                const markerData = dedupe(markers.map(marker => marker.getData()));

                if (markerData.length === 1) {
                    this.$emit('onMarkerTap', markerData[0]);
                }
                else {
                    this.$emit('onMultiMarkerTap', markerData);
                }
            }
        },

        /**
         * Emits a tap event with the id of the tapped marker
         * @param id of the marker that was tapped
         **/
        markerPointerEnterEvent: function (e) {
            this.$emit('onMarkerPointerEnter', e.target.getData())
        },

        /**
         * Emits a tap event with the id of the tapped marker
         * @param id of the marker that was tapped
         **/
        markerPointerLeaveEvent: function (e) {
            this.$emit('onMarkerPointerLeave', e.target.getData())
        },

        /**
         * Shows a map marker on the map (or updates if exists)
         * @param id of the marker
         * @param type of the marker (VEHICLE, OBSERVATION, EQUIPMENT, ...)
         * @param lat coordinate for the marker
         * @param lng coordinate for the marker
         * @param iconElement as HTML or SVG code
         * @param draggable boolean
         */
        // eslint-disable-next-line
        showMapMarker: function (id, type, lat, lng, iconElement, draggable = false, zIndex = 5, shadow = false, externalId = null) {
            this.hideMarkerIfExists(id, type)
            if (!this.markers[type]) {
                this.markers[type] = []
            }
            let icon = new H.map.DomIcon(iconElement, {anchor: {x:10, y:12}, size: {w:24, h:20}})
            let marker = new H.map.DomMarker({lat: lat, lng: lng}, {
                icon: icon
            })
            marker.draggable = draggable
            marker.addEventListener('tap', this.markerTapEvent)
            marker.addEventListener('pointerenter', this.markerPointerEnterEvent)
            marker.addEventListener('pointerleave', this.markerPointerLeaveEvent)
            marker.setData(externalId ? {id: externalId, type: type} : {id: id, type: type})
            marker.setZIndex(zIndex)
            this.markers[type][id] = marker
            this.map.addObject(marker)
            if (draggable) {
                // disable the default draggability of the underlying map
                // when starting to drag a marker object:
                marker.addEventListener('dragstart', function () {
                    this.mapBehavior.disable()
                }.bind(this))

                // re-enable the default draggability of the underlying map
                // when dragging has completed
                marker.addEventListener('dragend', function (ev) {
                    let pointer = ev.currentPointer
                    let position = this.map.screenToGeo(pointer.viewportX, pointer.viewportY)
                    this.dragging = false
                    this.$emit('onDragEnd',  {
                        id:id,
                        position: {
                            x: position.lng,
                            y: position.lat
                        }})
                    this.mapBehavior.enable()
                }.bind(this))

                marker.addEventListener('drag', function (ev) {
                    let pointer = ev.currentPointer
                    let position = this.map.screenToGeo(pointer.viewportX, pointer.viewportY)
                    ev.target.setGeometry(position)
                    this.dragging = true
                    this.$emit('onDrag', {
                        id:id,
                        position: {
                            x: position.lng,
                            y: position.lat
                        }})
                }.bind(this))
            }
        },

        hideMarkerIfExists: function (id, type) {
            if (this.markers[type]) {
                let marker = this.markers[type][id]
                if (marker !== undefined) {
                    this.map.removeObject(marker)
                }
            }
        },

        removeEquipmentGroup: function () {
            let bubbles = this.ui.getBubbles()
            for (let i = bubbles.length - 1; i >= 0; i--) {
                this.ui.removeBubble(bubbles[i])
            }
            if (this.equipmentGroup !== null) {
                this.map.removeObject(this.equipmentGroup)
                this.equipmentGroup = null
            }
        },

        removeMapMarker: function (id, type) {
            if (this.markers[type]) {
                let marker = this.markers[type][id]
                if (marker !== undefined) {
                    this.map.removeObject(marker)
                    this.markers[type][id] = undefined
                }
            }
        },

        removeMapMarkerByType: function (type) {
            if (type && this.markers[type]) {
                this.markers[type].forEach(function (marker) {
                    this.map.removeObject(marker)
                }, this)
                this.markers[type] = []
            }
        },

        getPolylineLength(id){
            return this.polylines[id].getPointCount()
        },

        getPolygonLength(id, type = this.AREA){
            return this.polygonLines[type][id].getPointCount()
        },

        attachEventListenersToAreas: function () {
            this.polygons.forEach(type =>{
                type.forEach(polygon => {
                    if (polygon) {
                        polygon.addEventListener('tap', this.areaTapEvent)
                    }
                })
            })
        },

        removeAreaEventListeners: function () {
            this.polygons.forEach(type =>{
                type.forEach(polygon => {
                    if (polygon) {
                        polygon.removeEventListener('tap', this.areaTapEvent)
                    }
                })
            })
        },

        /**
         * Show video route
         * @param color, route color
         * @param routeId, video metadata id
         * @param route, route coordinate points
         */
        drawObservationVideoRoute: function (color, routeId, route) {
            if (route.length > 1) {
                let line = new H.geo.LineString()
                route.forEach(point => {
                    line.pushLatLngAlt(point.lat, point.lon, 10)
                })
                let polyline = new H.map.Polyline(line, {
                    style: {lineWidth: 5, strokeColor: color},
                    arrows: {fillColor: 'white', frequency: 5, width: 2, length: 2}
                })
                polyline.setData(routeId)
                this.map.addObject(polyline)
                if (!this.polylines[this.TRACE]) {
                    this.polylines[this.TRACE] = []
                }
                this.polylines[this.TRACE][routeId] = polyline
            }
        },

        drawSimulatedRoute: function (routeId, route) {
            if (route.length > 1) {
                let line = new H.geo.LineString()
                route.forEach(point => {
                    line.pushLatLngAlt(point.y, point.x, 10)
                })
                let rgb = this.hexToRgb('#808080')
                let transparency = 0.75
                let lineColor = 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', ' + transparency + ')'
                let polyline = new H.map.Polyline(line, {
                    style: {
                        lineWidth: 3,
                        strokeColor: lineColor,
                        lineDash: [2],
                        lineHeadCap: "arrow-head",
                        lineTailCap: "arrow-tail"
                    }
                })
                polyline.setData(routeId)
                polyline.setZIndex(3)
                this.map.addObject(polyline)
                // Draw a solid white line as background to highlight arrows
                let backgroundPolyline = new H.map.Polyline(line, {
                    style: {
                        lineWidth: 3,
                        strokeColor: "rgba(255, 255, 255, 0.5)"
                    }
                })
                backgroundPolyline.setData(routeId)
                backgroundPolyline.setZIndex(2)
                this.map.addObject(backgroundPolyline)

                if (!this.polylines[this.TRACE]) {
                    this.polylines[this.TRACE] = []
                }
                this.polylines[this.TRACE][routeId] = polyline
                this.polylines[this.TRACE]["bg_" + routeId] = backgroundPolyline
            }
        },

        hideSimulatedRoute: function (routeId) {
            if (this.polylines[this.TRACE] && this.polylines[this.TRACE][routeId])   {
                this.map.removeObject(this.polylines[this.TRACE][routeId])
                this.map.removeObject(this.polylines[this.TRACE]["bg_" + routeId])
            }
        },


        /**
         * Removes route progress lines from map
         * @param routeId
         */
        removeRouteProgressPolyLine: function (routeId) {
            if (this.videoRoutes[routeId] !== undefined) {
                this.map.removeObject(this.videoRoutes[routeId])
                this.videoRoutes[routeId] = undefined
            }
        },

        /**
         * Show route progress
         * @param pointIndex, current video time coordinate
         * @param routeId, video metadata id
         * @param videoEnded, Fill rest of the route if coordinates goes over the video
         */
        drawRouteProgressPolyLine: function (pointIndex, routeId, videoEnded = false, color = '#7CFC00') {
            let routeProgressPolyline
            // Clear old route progress line
            if (this.videoRoutes[routeId] !== undefined) {
                routeProgressPolyline = this.videoRoutes[routeId]
            }
            let line = new H.geo.LineString()
            let point = 0
            if (this.polylines[this.TRACE][routeId]) {
                if (videoEnded) {
                    this.polylines[this.TRACE][routeId].getGeometry().eachLatLngAlt((lat, lng, alt, index) => {
                        line.pushLatLngAlt(lat, lng, alt)
                        point = index
                    })
                } else {
                    this.polylines[this.TRACE][routeId].getGeometry().eachLatLngAlt((lat, lng, alt, index) => {
                        if (index <= pointIndex) {
                            line.pushLatLngAlt(lat, lng, alt)
                            point = index
                        }
                    })
                }
                // Line string need at least two points
                if (point > 0) {
                    if (routeProgressPolyline) {
                        routeProgressPolyline.setGeometry(line)
                    } else {
                        let rgb = this.hexToRgb(color)
                        let transparency = 0.5
                        let lineColor = 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', ' + transparency + ')'
                        let routeProgressPolyLine = new H.map.Polyline(line, {style: {lineWidth: 5, strokeColor: lineColor}})
                        routeProgressPolyLine.setData(routeId)
                        routeProgressPolyLine.setZIndex(4)
                        this.videoRoutes[routeId] = routeProgressPolyLine
                        this.map.addObject(routeProgressPolyLine)
                    }
                }
            }
        },

        removeRouteProgressPolyLines: function (videos) {
            videos.forEach(videoId => {
                // Clear old route progress line
                if (this.videoRoutes[videoId] !== undefined) {
                    this.map.removeObject(this.videoRoutes[videoId])
                    this.videoRoutes[videoId] = undefined
                }
            })
        },

        hexToRgb: function (hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        },

        getNearestPoint: function (clickedCoord, routeId) {
            let distFromCurrent = function (coord) {
                return {coord: coord, dist: getDistance(clickedCoord, coord)}
            }
            let points = []
            this.polylines[this.TRACE][routeId].getGeometry().eachLatLngAlt((lat, lon) => {
                points.push({lat: lat, lon: lon})
            })
            let Point = points.map(distFromCurrent).sort(function (a, b) {
                return a.dist - b.dist
            })[0]
            let PointIndex = points.findIndex(point => point.lat === Point.coord.lat && point.lon === Point.coord.lon)
            this.drawRouteProgressPolyLine(PointIndex, routeId)
            return PointIndex
        },

        getPolylineGeometry(id) {
            let points = []
            this.polylineLines[id] && this.polylineLines[id].getGeometry().eachLatLngAlt((lat, lon) => {
                points.push([lat, lon])
            })
            return points
        },

        getPointGeometry(id, type = undefined) {
            let point = this.markers[type] && this.markers[type][id].getGeometry()
            return point ? [point.lng, point.lat] : null
        },

        getPolygonGeometry(id, type = this.AREA) {
            let points = []
            this.polygons[type][id].getGeometry().eachLatLngAlt((lat, lon) => {
                points.push([lat, lon])
            })
            return points
        },

        getMapType() {
            return 'HERE'
        },

        hideMapControls() {

        }

    },
}
</script>
<style scoped>
#right-click-menu li:not(.context-menu-coordinates) {
    cursor: pointer;
}

.routa-controls {
    width: 15em;
    height: 2.5em;
}

.H_ib_content {
    margin: 5px 10px !important;
    font-size: 1.75em;
    line-height: 1.8em;
}

.H_ib_close svg.H_icon {
    fill: #FFFFFF;
}

.H_ib_close {
    top: 1.3em;
    width: 10px;
    height: 10px;
}

.popup-menu-item {
    width: 100% !important;
    border-radius: 0;
    text-align: left !important;
    color: #FFFFFF;
}

.popup-sub-menu-item {
    text-align: left;
    font-size: .9em;
}

/deep/ .btn-sm {
    border: none;
    border-radius: 0;
}

/deep/ #right-click-menu li {
    padding: 0;
}

/deep/ .dropdown-menu {
    padding: 0;
}

/deep/ .dropdown-item {
    padding: .25em .5em;
}


</style>
