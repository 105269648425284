import axios from 'axios'

// var RESTAPI = process.env.VUE_APP_RESTAPI
var RESTAPI = 'https://demo.routaapi.com/'
// var RESTAPI = 'http://127.0.0.1:8000/'

export const restApi = {
    data: function () {
        return {
            sharedDocumentUrl: RESTAPI + 'sharedDocument',
            sharedDocumentCategoryUrl: RESTAPI + 'sharedDocumentCategory',
            invoicedLicenseUrl: RESTAPI + 'invoicedLicenseUrl',
            invoiceSummaryUrl: RESTAPI + 'report/invoicesummary',
            expiredLicenseUsersAdminURL: RESTAPI + 'expiredLicenseUsersAdminView',
            licenseUsersAdminURL:RESTAPI + 'licenseUsersAdminView',
            usersAndCompaniesURL: RESTAPI + 'usersAndCompanies',
            vehicleUrl: RESTAPI + 'vehicle',
            inspectionReportUrl: RESTAPI + 'inspectionReport',
            inspectionReportGenericUrl: RESTAPI + 'inspectionReport/generic',
            inspectionResult: RESTAPI + 'inspectionResult/',
            inspectionTemplateUrl: RESTAPI + 'inspectionTemplate',
            defaultInspectionUrl: RESTAPI + 'default/worknspectiontemplates',
            defaultTaskTypeUrl: RESTAPI + 'default/tasktypes',
            inspectableUrl: RESTAPI + 'vehicle/inspectable',
            contractInspectionUrl: RESTAPI + 'contract/inspection',
            pricingModeUrl: RESTAPI + 'pricingmodel',
            pricingModeConversionUrl: RESTAPI + 'pricingmodel/conversion',
            priceListUrl: RESTAPI + 'pricelist',
            priceListWeightDistanceUrl: RESTAPI + 'pricelist/weightdistance',
            areaPriceListUrl: RESTAPI + 'pricelist/area',
            taskTypeConversionUrl: RESTAPI + 'tasktype/conversion',
            taskTypeUrl: RESTAPI + 'tasktype',
            taskTypeAllUrl: RESTAPI + 'tasktype/all',
            userUrl: RESTAPI + 'user',
            userCompanyUrl: RESTAPI + 'user/company',
            userRoleUrl: RESTAPI + 'user/role',
            resetPasswordUrl: RESTAPI + 'user/password/reset',
            resetPasswordAdminUrl: RESTAPI + 'user/password/reset/admin',
            changePasswordUrl: RESTAPI + 'user/password',
            respPersonUrl: RESTAPI + 'user/responsiblePerson',
            userInfoUrl: RESTAPI + 'userinfo',
            companyTokenUrl: RESTAPI + 'user/token/company',
            contractUrl: RESTAPI + 'contract',
            contractCollectionUrl: RESTAPI + 'contract/collection',
            contractAttachmentUrl: RESTAPI + 'contract/attachment/',
            contractAttachmentRemoveUrl: RESTAPI + 'contract/attachment/removal',
            contractXlsxUrl: RESTAPI + 'contract/xlsx',
            contractOpenUrl: RESTAPI + 'contract/open',
            contractTaskTypeUrl: RESTAPI + 'contractTaskType',
            companyUrl: RESTAPI + 'company',
            customerUrl: RESTAPI + 'company/customer',
            contractorUrl: RESTAPI + 'company/contractor',
            contractUserUrl: RESTAPI + 'contract/users',
            contractCompanyUrl: RESTAPI + 'company/contract',
            contractImportUrl: RESTAPI + 'contract/import',
            companyRelationshipUrl: RESTAPI + 'companyRelationship',
            roadLinkUrl: RESTAPI + 'roadlink',
            roadAddressUrl: RESTAPI + 'roadaddress',
            roadGeometryPointsUrl: RESTAPI + 'roadgeometry/points',
            roadGeometryPointUrl: RESTAPI + 'roadgeometry/point',
            roadSectionUrl: RESTAPI + 'roadgeometry/distance',
            roadGeometryUrl: RESTAPI + 'roadgeometry/name',
            roadGeometryLocationUrl: RESTAPI + 'roadgeometry/location',
            orderUrl: RESTAPI + 'order',
            orderAttachmentUrl: RESTAPI + 'order/attachment/',
            orderOpenUrl: RESTAPI + 'order/open',
            orderCloseUrl: RESTAPI + 'order/close',
            orderStatusUrl: RESTAPI + 'order/status',
            orderDateUrl: RESTAPI + 'order/date',
            orderGeometriesUrl: RESTAPI + 'order/geometries',
            orderPriceHistoryUrl: RESTAPI + 'order/pricehistory',
            extContractIdUrl: RESTAPI + 'contract/external',
            extContractIdCompanyUrl: RESTAPI + 'contract/external/company',
            tripUrl: RESTAPI + 'trip',
            tripRoadsUrl: RESTAPI + 'trip/roads',
            trafficControlUrl: RESTAPI + 'trafficcontrol',
            trafficControlMapUrl: RESTAPI + 'trafficcontrol/map',
            trafficControlDetailsUrl: RESTAPI + 'trafficcontrol/details',
            trafficControlReportUrl: RESTAPI + 'trafficcontrol/report',
            tripReportUrl: RESTAPI + 'trip/report',
            tripGeoJsonUrl: RESTAPI + 'trip/geojson',
            contractUserReportUrl: RESTAPI + 'contract/users/report',
            observationUrl: RESTAPI + 'observation',
            observationDetailsUrl: RESTAPI + 'observation/details',
            observationMapUrl: RESTAPI + 'observation/map',
            observationMapPublicUrl: RESTAPI + 'observation/map/public',
            observationsUrl: RESTAPI + 'observations',
            observationReportUrl: RESTAPI + 'observation/report',
            observationCollectionUrl: RESTAPI + 'observation/collection',
            observationRoadReportUrl: RESTAPI + 'observation/roadreport',
            observationRoadReportDataUrl: RESTAPI + 'observation/roadreport/data',
            tripUserUrl: RESTAPI + 'trip/user',
            spreaderDataUrl: RESTAPI + 'spreaderdata',
            spreaderDataToHarjaUrl: RESTAPI + 'spreaderdata/harja',
            searchAreaUrl: RESTAPI + 'searchArea',
            searchGroupUrl: RESTAPI + 'searchGroup',
            lastPositionUrl: RESTAPI + 'vehiclelog/lastposition',
            roadWorkRecordsUrl: RESTAPI + 'roadlink/tasks',
            skiTrackRecordsUrl: RESTAPI + 'skitrack/snapshot',
            loginUrl: RESTAPI + 'login',
            vehicleDefectUrl: RESTAPI + 'vehicle/defect',
            vehicleDefectOpenUrl: RESTAPI + 'vehicle/defect/open',
            vehicleDefectVehiclesUrl: RESTAPI + 'vehicle/defect/vehicles',
            vehicleDefectReporterUrl: RESTAPI + 'vehicle/defect/reporters',
            workTimeUrl: RESTAPI + 'worktime',
            workTimeContractUrl: RESTAPI + 'worktime/contracts',
            workTimeUserUrl: RESTAPI + 'worktime/user',
            workDayUrl: RESTAPI + 'worktime/day',
            workDayDetailsUrl: RESTAPI + 'worktime/day/details',
            workDayDetailsReportUrl: RESTAPI + 'worktime/day/details/report',
            workTimeReportUrl: RESTAPI + 'worktime/report',
            workTimeModeUrl: RESTAPI + 'worktimemode',
            workTimeApproveUrl: RESTAPI + 'worktime/approval',
            costCenterUrl: RESTAPI + 'costcenter',
            observationGroupUrl: RESTAPI + 'observation/group',
            observationTypeUrl: RESTAPI + 'observation/type',
            observationVideoMetadataUrl: RESTAPI + 'recording',
            observationVideosUrl: RESTAPI + 'observation/recording',
            reporterUrl: RESTAPI + 'user/reporter',
            userLocationUrl: RESTAPI + 'user/location/latest',
            areaUrl: RESTAPI + 'area',
            areaWorkUrl: RESTAPI + 'area/work',
            areaWorkReportXlsxUrl: RESTAPI + 'area/work/xlsx',
            areaTypeUrl: RESTAPI + 'area/type',
            areaEquipmentUrl: RESTAPI + 'area/equipment',
            areaEditEquipmentUrl: RESTAPI + 'area/equipment/edit',
            areaEquipmentPhotoUrl: RESTAPI + 'area/equipment/photo/',
            areaReportUrl: RESTAPI + 'area/report',
            areaReportPhotoUrl: RESTAPI + 'area/report/photo/',
            areaReportSubmitUrl: RESTAPI + 'area/report/submit',
            areaDefectUrl: RESTAPI + 'area/defect',
            areaDefectOpenUrl: RESTAPI + 'area/defect/open',
            areaDefectAreasUrl: RESTAPI + 'area/defect/areas',
            areaDefectReporterUrl: RESTAPI + 'area/defect/reporters',
            areaGroupUrl: RESTAPI + 'area/group',
            equipInsResultUrl: RESTAPI + 'area/report/inspectionresult/',
            workReportUrl: RESTAPI + 'workreport',
            contractDiaryUrl: RESTAPI + 'contract/diary',
            forestCodeUrl: RESTAPI + 'forestcode',
            forwarderWorkReportUrl: RESTAPI + 'forest/forwarder/report',
            forwarderWorkReportExcelUrl: RESTAPI + 'forest/forwarder/report/excel',
            harvesterWorkReportExcelUrl: RESTAPI + 'forest/harvester/report/excel',
            harvesterWorkReportUrl: RESTAPI + 'forest/harvester/report',
            workReportsTripLogUrl: RESTAPI + 'workreport/triplog',
            consignmentNoteUrl: RESTAPI + 'workreport/consignment_note/photo/',
            recipeUrl: RESTAPI + 'workreport/recipe/photo/',
            observationPhotoUrl: RESTAPI + 'observation/photo',
            certificateTypeUrl: RESTAPI + 'certificate/type',
            certificateUrl: RESTAPI + 'certificate',
            certificateManyUrl: RESTAPI + 'certificate/many',
            dailyAllowanceUrl: RESTAPI + 'dailyallowance',
            dailyAllowanceReportUrl: RESTAPI + 'dailyallowance/report',
            dailyAllowanceApproveUrl: RESTAPI + 'dailyallowance/approval/',
            mealAllowanceUrl: RESTAPI + 'mealallowance',
            mealAllowanceApproveUrl: RESTAPI + 'mealallowance/approval/',
            workAssignmentUrl: RESTAPI + 'workassignment',
            workAssignmentsUrl: RESTAPI + 'workassignments',
            workAssignmentByDefectUrl: RESTAPI + 'workassignment/defect',
            workAssignmentUserUrl: RESTAPI + 'workassignment/user',
            workAssignmentIncompleteUrl: RESTAPI + 'workassignment/incomplete',
            workAssignmentUnassignedUrl: RESTAPI + 'workassignment/unassigned',
            workAssignmentStartUrl: RESTAPI + 'workassignment/start',
            workAssignmentAssignUrl: RESTAPI + 'workassignment/assign',
            workAssignmentCompleteUrl: RESTAPI + 'workassignment/complete',
            workAssignmentAttachmentUrl: RESTAPI + 'workassignment/attachment/',
            workAssignmentProgressUrl: RESTAPI + 'workassignment/progress/',
            contractDailyTripSummaryUrl: RESTAPI + 'report/dailytrips',
            contractDailyTripSummaryApprovalUrl: RESTAPI + 'report/dailytrips/approval',
            vehicleAllocationSummaryUrl: RESTAPI + 'report/vehicleallocation',
            contractDailyTripSummaryReportUrl: RESTAPI + 'report/dailytrips/file',
            generateContractDailyTripSummaryUrl: RESTAPI + 'report/dailytrips/generate',
            setInvoicedDailyTripSummaryUrl: RESTAPI + 'report/dailytrips/invoiced',
            setInvoiceStatesDailyTripSummaryUrl: RESTAPI + 'report/dailytrips/invoicestates',
            setInvoiceableSumDailyTripSummaryUrl: RESTAPI + 'report/dailytrips/invoiceablesum',
            setInvoiceChangesDailyTripSummaryUrl: RESTAPI + 'report/dailytrips/invoicechanges',
            contractorReportUrl: RESTAPI + 'report/contractor',
            sportTrackUrl: RESTAPI + 'sporttrack',
            skiingTrackUrl: RESTAPI + 'sporttrack/skiing',
            skiingTrackWorkUrl: RESTAPI + 'skitrack/work',
            trackingDeviceUrl: RESTAPI + 'trackingdevice',
            materialTypeUrl: RESTAPI + 'material/type',
            materialStationUrl: RESTAPI + 'materialstation',
            materialStationScaleUrl: RESTAPI + 'materialstation/scale',
            scaleRecordUrl: RESTAPI + 'scalerecord',
            tripSummaryUrl: RESTAPI + 'tripsummary',
            subcontractorApplicationUrl: RESTAPI + 'subcontractorApplication',
            materialDeliveryUrl: RESTAPI + 'materialdelivery',
            materialDeliveryReportUrl: RESTAPI + 'materialdelivery/report',
            weighingNoteUrl: RESTAPI + 'weighingnote',
            weighingNoteAttachmentUrl: RESTAPI + 'weightingnote/attachment/',
            materialReportUrl: RESTAPI + 'report/material',
            materialReportExcelUrl: RESTAPI + 'report/material/excel',
            efficiencyUrl: RESTAPI + 'efficiency',
            efficiencyCalculateUrl: RESTAPI + 'efficiency/calculate/forest',
            materialStorageUrl: RESTAPI + 'materialstorage',
            materialStorageSearchListUrl: RESTAPI + 'materialstorage/searchlist',
            materialStorageAttachmentUrl: RESTAPI + 'materialstorage/attachment/',
            materialStorageContractsUrl: RESTAPI + 'materialstorage/contracts',
            dangerReportUrl: RESTAPI + 'danger/report',
            emailListUrl: RESTAPI + 'emaillist',
            userAlertUrl: RESTAPI + 'user/alert',
            workPermissionUrl: RESTAPI + 'workpermission',
            notification: RESTAPI + 'notification',
            notificationSubsUrl: RESTAPI + 'notification/topic/subscriptions',
            userDevice: RESTAPI + 'user/device',
            roadSignUrl: RESTAPI + 'roadsign',
            roadSignExtUrl: RESTAPI + 'ext/roadsign',
            roadSignsUrl: RESTAPI + 'roadsigns',
            roadSignXlsxUrl: RESTAPI + 'roadsign/report',
            roadSignPhotoUrl: RESTAPI + 'roadsign/photo/',
            extRoadSignsUrl: RESTAPI + 'ext/roadsign',
            extPostsUrl: RESTAPI + 'ext/post',
            extPortalUrl: RESTAPI + 'ext/portal',
            extCulvertsUrl: RESTAPI + 'ext/culvert',
            extRailingsUrl: RESTAPI + 'ext/railing',
            extDrainsUrl: RESTAPI + 'ext/drain',
            extFencesUrl: RESTAPI + 'ext/fence',
            extStairsUrl: RESTAPI + 'ext/stair',
            extGatesUrl: RESTAPI + 'ext/gate',
            exteEdgeSupportsUrl: RESTAPI + 'ext/edgesupport',
            exteEdgePilesUrl: RESTAPI + 'ext/edgepile',
            extRoadsideFurnitureUrl: RESTAPI + 'ext/roadsidefurniture',
            extRoadsideAdvertisementsUrl: RESTAPI + 'ext/roadsideadvertisement',
            extBoomsBarriersBollardsUrl: RESTAPI + 'ext/boombarrierbollard',
            extPipesWiresCablesUrl: RESTAPI + 'ext/pipewirecable',
            extLightingUrl: RESTAPI + 'ext/lighting',
            powerStationUrl: RESTAPI + 'powerstation',
            powerStationImportUrl: RESTAPI + 'powerstation/file',
            powerStationBulkUrl: RESTAPI + 'powerstation/bulk',
            powerStationMapUrl: RESTAPI + 'powerstation/map',
            powerStationXlsxUrl: RESTAPI + 'powerstation/report',
            powerStationPhotoUrl: RESTAPI + 'powerstation/photo',
            cableUrl: RESTAPI + 'cable',
            cableImportUrl: RESTAPI + 'cable/file',
            cableBulkUrl: RESTAPI + 'cable/bulk',
            streetLightUrl: RESTAPI + 'streetlight',
            streetLightImportUrl: RESTAPI + 'streetlight/file',
            streetLightBulkUrl: RESTAPI + 'streetlight/bulk',
            streetLightMapUrl: RESTAPI + 'streetlight/map',
            streetLightXlsxUrl: RESTAPI + 'streetlight/report',
            streetLightPhotoUrl: RESTAPI + 'streetlight/photo',
            streetLightsDetailsUrl: RESTAPI + 'streetlight/details',
            bridgeUrl: RESTAPI + 'bridge',
            culvertUrl: RESTAPI + 'culvert',
            additionalCostUrl: RESTAPI + 'additionalcost',
            contractTripCostUrl: RESTAPI + 'contract/tripcost',
            contractWorkCostUrl: RESTAPI + 'contract/workinghourcost',
            mapKeyUrl: RESTAPI + 'mapkey',
            apiKeyUrl: RESTAPI + 'apikey',
            apiUrl: RESTAPI + 'apikey/url',
            convertShapeToGeoJsonUrl: RESTAPI + 'converter/shapefiletoserver',
            convertGeoJsonToShapeUrl: RESTAPI + 'converter/geojsontoserver',
            stormSewerUrl: RESTAPI + 'stormsewerline',
            stormSewerMapUrl: RESTAPI + 'stormsewerline/map',
            stormSewerBulkUrl: RESTAPI + 'stormsewerline/bulk',
            stormSewerAttachmentRemoveUrl: RESTAPI + 'stormsewerline/attachment/removal',
            stormDrainUrl: RESTAPI + 'stormdrain',
            stormDrainMapUrl: RESTAPI + 'stormdrain/map',
            stormDrainBulkUrl: RESTAPI + 'stormdrain/bulk',
            stormDrainAttachmentRemoveUrl: RESTAPI + 'stormdrain/attachment/removal',
            stormSewerItems: RESTAPI + 'stormseweritems/bulk',
            weatherStationsUrl: RESTAPI + 'weatherStations',
            harjaAlertUrl: RESTAPI + 'harja/alert',
            extWorkDiaryUrl: RESTAPI + 'ext/workdiary',
            extEquipmentInspectionUrl: RESTAPI + 'ext/equipmentreport',
            extEquipmentSearchUrl: RESTAPI + 'ext/equipment',
            extRoadSafetyReportUrl: RESTAPI + 'ext/roadsafetyreport',
            extRoadInspectionReportUrl: RESTAPI + 'ext/roadinspection',
            extTasksUrl: RESTAPI + 'ext/tasks',
            extEquipmentUrl: RESTAPI + 'ext/equipment',
            extEquipmentRemovalUrl: RESTAPI + 'ext/equipment/removal',
            extEquipmentsReplaceUrl: RESTAPI + 'ext/equipments/replace',
            observationReportTemplateUrl: RESTAPI + 'observation/reporttemplate',
            copyContractUrl: (id) => `${RESTAPI}contract/${id}/copy`,
        }
    },
    computed: {
        axios() {
            return axios.create({
                baseURL: RESTAPI,
                headers: {Authorization: this.$store.state.token}
            });
        },
    },
    methods: {
        fetchUserPreferences(prefName) {
            return this.axios.get(`preferences/${prefName}`);
        },

        upsertUserPreferences(prefName, preferences) {
            return this.axios.put(`preferences/${prefName}`, preferences)
        },

        fetchLicenseReportUsers(date, companyId) {
            return this.axios.get('licenseReportUsers', {
                params: {
                    date,
                    company: companyId
                }
            });
        },

        fetchAllLicenseReportUsers(date) {
            return this.axios.get('allLicenseReportUsers', {
                params: {
                    date
                }
            });
        },

        fetchContractWeatherData(contractId, params) {
            return this.axios.get(`contract/${contractId}/weather`, { params })
        },

        restAdd: function (url, item, successCallback, failCallback) {
            axios.post(url, item, {
                headers: {Authorization: this.$store.state.token}
            })
                .then(response => {
                    if (response.status === 200) {
                        successCallback(response.data)
                    } else {
                        failCallback(response)
                    }
                })
                .catch(e => {
                    failCallback(e.response)
                })
        },

        restPostForm(url, formData, successCallback, failCallback) {
            axios.post(url, formData, {
                'Content-Type': 'multipart/form-data',
                headers: {Authorization: this.$store.state.token}
            }).then(response => {
                if (response.status === 200) {
                    successCallback(response.data)
                } else {
                    failCallback(response.data)
                }
            })
                .catch(e => {
                    failCallback(e.response)
                })
        },

        restPostWithFile: function (url, data, formData, successCallback, failCallback) {
            // Convert to formData
            if (formData === null) {
                formData = new FormData()
            }
            formData.append('json', JSON.stringify(data))
            axios.post(url, formData, {
                'Content-Type': 'multipart/form-data',
                headers: {Authorization: this.$store.state.token}
            }).then(response => {
                if (response.status === 200) {
                    successCallback(response.data)
                } else {
                    failCallback(response.data)
                }
            })
                .catch(e => {
                    failCallback(e.response ? e.response.data : null)
                })
        },

        restUpdate: function (url, item, successCallback, failCallback) {
            axios.put(url, item, {
                headers: {Authorization: this.$store.state.token}
            })
                .then(response => {
                    if (response.status === 200) {
                        successCallback(response.data)
                    } else {
                        failCallback(response)
                    }
                })
                .catch(e => {
                    failCallback(e.response)
                })
        },

        restDelete: function (url, successCallback, failCallback) {
            axios.delete(url, {
                headers: {Authorization: this.$store.state.token}
            })
                .then(response => {
                    if (response.status === 200) {
                        successCallback(response.data)
                    } else {
                        failCallback(response.data)
                    }
                })
                .catch(e => {
                    failCallback(e.response.data)
                })
        },

        restDeleteItem: function (url, item, successCallback, failCallback) {
            axios.delete(url, {
                headers: {Authorization: this.$store.state.token},
                data: item
            })
                .then(response => {
                    if (response.status === 200) {
                        successCallback(response.data)
                    } else {
                        failCallback(response.data)
                    }
                })
                .catch(e => {
                    failCallback(e.response.data)
                })
        },

        restFetch: function (url, callback, errorCallback = null) {
            axios.get(url, {
                headers: {Authorization: this.$store.state.token}
            })
                .then(response => {
                    callback(response)
                })
                .catch(e => {
                    if (errorCallback) {
                        errorCallback(e)
                    } else {
                        callback(null)
                    }
                })
        },

        //Fetch without authorization
        restFetchWithoutAuthorization: function (url, callback, errorCallback = null) {
            axios.get(url, {
            })
                .then(response => {
                    callback(response)
                })
                .catch(e => {
                    if (errorCallback) {
                        errorCallback(e)
                    } else {
                        callback(null)
                    }
                })
        },

        restFetchParams: function (url, params, callback, errorCallback = null) {
            axios.get(url, {
                headers: {
                    Authorization: this.$store.state.token
                },
                params: params
            })
                .then(response => {
                    callback(response)
                })
                .catch(e => {
                    if (errorCallback) {
                        errorCallback(e)
                    } else {
                        callback(null)
                    }
                })
        },

        restFetchOpenData : function (url, params, callback, errorCallback = null) {
            axios.get(url, {
                params: params
            })
                .then(response => {
                    callback(response)
                })
                .catch(e => {
                    if (errorCallback) {
                        errorCallback(e)
                    } else {
                        callback(null)
                    }
                })
        },

        restFetchVideo: function (url, params, callback, errorCallback = null) {
            axios.get(url, {
                headers: {Authorization: this.$store.state.token}
            })
                .then(response => {
                    callback(response, params)
                })
                .catch(e => {
                    if (errorCallback) {
                        errorCallback(e)
                    } else {
                        callback(null)
                    }
                })
        },

        restPostDownloadFile: function (url, params, callback, failCallback = null) {
            axios.post(url, params, {
                headers: {Authorization: this.$store.state.token},
                responseType: 'arraybuffer'
            })
                .then(response => {
                    if (response.status === 200) {
                        callback(response)
                    } else {
                        failCallback(response)
                    }
                })
                .catch(e => {
                    failCallback(e.response)
                })
        },

        restGetDownloadFile: function (url, params, callback, failCallback = null) {
            axios.get(url, {
                headers: {Authorization: this.$store.state.token},
                params: params,
                responseType: 'arraybuffer'
            })
                .then(response => {
                    if (response.status === 200) {
                        callback(response)
                    } else {
                        failCallback(response)
                    }
                })
                .catch(e => {
                    failCallback(e.response)
                })
        },

        timestampToDateInput: function (timestamp) {
            if (!timestamp || timestamp < 1) {
                return null
            }
            var date = new Date(timestamp)
            var year = date.getFullYear()
            var month = date.getMonth() + 1
            var day = date.getDate()
            var result = year + '-'
            if (month < 10) {
                result += '0'
            }
            result += month + '-'
            if (day < 10) {
                result += '0'
            }
            result += day
            return result
        }
    }
}
