<template>
    <div ref="container" class="content-container">
        <b-navbar
            variant="faded"
        >
            <div
                class="header"
            >
                <span class="title">{{ $t('menu.invoicing_accepting') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <transition-group name="fade">
                <div key="search-contract-list">
                    <keep-alive>
                        <invoicing-list
                            :results="results"
                            @showDetails="showInvoiceSummary"
                            @acceptInvoicing="acceptInvoicing"
                        />
                    </keep-alive>
                </div>
            </transition-group>
            <transition name="fade">
                <b-modal
                    v-model="detailsMode"
                    size="lg"
                    body-class="fill-modal"
                    ref="companyModal"
                    centered
                    no-close-on-esc
                    no-close-on-backdrop
                    hide-header-close
                    hide-footer
                    >
                    <div class="col-sm-12">
                        <contract-trip-summary-list
                            :summaries="summaries"
                            :params="searchParams"
                            :invoice-state-options="invoiceStateOptions"
                            :user="user"
                            @updateRequest="fetchInvoiceSummaries"
                        />
                    </div>
                    <div class="col-12 button-container">
                        <b-button size="sm" class="form-button" variant="primary" @click="showList">
                            {{ $t('common.close') }}
                        </b-button>
                    </div>
                </b-modal>
            </transition>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '@/components/mixins/TimeUtils'
import {invoiceStates, invoiceStateHelper} from "@/components/invoice/InvoiceMixin";
import InvoicingList from "@/components/invoice/InvoicingList.vue";
import ContractTripSummaryList from "@/components/tripsummary/ContractTripSummaryList.vue";
import {EventBus} from "@/event-bus";

export default {
    name: 'Invoicing',
    components: {
        ContractTripSummaryList,
        InvoicingList,
    },
    mixins: [workManagementHelper, restApi, timeUtils, invoiceStateHelper, invoiceStates],
    props: {
        user: {
            type: Object,
            default: null
        },
        isObserver: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            results: [],
            summaries: [],
            searchParams: null,
            detailsMode: false,
            loading: false,
            lastSearchedParams: null,
        }
    },
    mounted: function () {
        this.fetchInvoiceSummaries()
    },
    computed: {
        invoiceStateOptions() {
            let invoiceStates = this.getInvoiceStates();
            return [...invoiceStates]
        }
    },
    methods: {
        fetchInvoiceSummaries: function (params) {
            this.loading = true
            this.lastSearchedParams = params
            this.restFetchParams(this.invoiceSummaryUrl, this.lastSearchedParams, this.handleSummariesResponse)
        },

        handleSummariesResponse: function (response) {
            this.loading = false
            if (response && response.data) {
                this.results = response.data
            }
        },

        fetchInvoiceRows() {
            this.loading = true
            this.restFetchParams(this.contractDailyTripSummaryUrl, this.searchParams, this.handleInvoiceRowsResponse, this.invoiceRowsFail)
        },

        invoiceRowsFail: function () {
            this.loading = false
            this.summaries = []
        },

        handleInvoiceRowsResponse: function (response) {
            this.loading = false
            this.summaries = response.data
            if(this.summaries && this.summaries.length > 0) {
                this.detailsMode = true
            } else {
                EventBus.$emit('show-alert', this.$t('common.error'))
            }
        },

        showInvoiceSummary(item) {
            this.searchParams = {
                to: this.getDateAsString(new Date(item.end_date)),
                from: this.getDateAsString(new Date(item.start_date)),
                pricingModel: item.pricing_model ? item.pricing_model.id : null,
                contractIds: [item.order.contract.id],
                orders: [item.order.id],
                invoiceState: 2
            }
            this.fetchInvoiceRows()
        },

        acceptInvoicing(item) {
            if(item && item.orders && item.orders.length > 0) {
                this.loading = true
                this.restUpdate(this.contractDailyTripSummaryApprovalUrl, {rows: item.orders}, this.acceptInvoicingSuccess, this.acceptInvoicingFail)
            }
        },

        acceptInvoicingSuccess() {
            this.loading = false
            this.fetchInvoiceSummaries()
        },

        acceptInvoicingFail() {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('daily_summary.invoice_accepting_failed'))
        },

        showList: function () {
            this.detailsMode = false
            this.summaries = []
        },
    }
}
</script>
